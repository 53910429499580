import React from 'react';
import { motion } from 'framer-motion';
import { 
  Car, 
  Star,
  StarHalf,
  HelpCircle,
  Navigation,
  Users,
  Shield,
  Check 
} from 'lucide-react';
import { 
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Box,
} from '@mui/material';
import car1 from '../../Assets/car1.webp';
import car2 from '../../Assets/car2.jpg';
import car3 from '../../Assets/car3.png';
import car4 from '../../Assets/car4.avif';
import '@fortawesome/fontawesome-free/css/all.css';
import Carousel from '../UI/Carousel';

// Animation variants
const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

// Enhanced Fleet data
const fleetData = [
  {
    id: 1,
    name: 'White Toyota Rush GTR',
    image: car1,
    description: 'Spacious and versatile SUV, perfect for family adventures and group travels.',
    capacity: 7,
    fuelType: 'Gasoline',
    transmission: 'Automatic',
    extras: [
      'Chauffeur Included',
      'Air Conditioning',
      'Bluetooth Audio',
      'Spacious Luggage Area'
    ],
    safetyFeatures: [
      'Anti-lock Braking System (ABS)',
      'Airbags',
      'Rear Parking Sensors'
    ]
  },
  {
    id: 2,
    name: 'Gray Honda Civic',
    image: car2,
    description: 'Sleek and modern sedan offering comfort and efficiency for city and highway driving.',
    capacity: 5,
    fuelType: 'Gasoline',
    transmission: 'Automatic',
    extras: [
      'Chauffeur Included',
      'Leather Seats',
      'GPS Navigation',
      'USB Charging Ports'
    ],
    safetyFeatures: [
      'Collision Mitigation Braking',
      'Lane Keeping Assist',
      'Adaptive Cruise Control'
    ]
  },
  {
    id: 3,
    name: 'Silver Toyota Hi-Ace',
    image: car3,
    description: 'Robust and spacious van designed for comfortable group transportation.',
    capacity: 12,
    fuelType: 'Diesel',
    transmission: 'Manual',
    extras: [
      'Chauffeur Included',
      'Wide Comfortable Seating',
      'Overhead Storage',
      'Large Windows'
    ],
    safetyFeatures: [
      'Stability Control',
      'Multiple Airbags',
      'Reinforced Body Structure'
    ]
  },
  {
    id: 4,
    name: 'Red Mitsubishi Adventure',
    image: car4,
    description: 'Rugged and reliable MPV, ideal for both urban commutes and rural expeditions.',
    capacity: 8,
    fuelType: 'Gasoline',
    transmission: 'Manual',
    extras: [
      'Chauffeur Included',
      'Flexible Seating Arrangement',
      'Roof Rack',
      'High Ground Clearance'
    ],
    safetyFeatures: [
      'Roll Stability Control',
      'Side Impact Protection',
      'Emergency Stop Signal'
    ]
  },
];

class HomePage extends React.Component {
  render() {
      return (
          <motion.div 
              className="bg-gray-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
          >
                {/* Hero Section - Responsive Typography */}
                <div className="pt-16 sm:pt-32 flex flex-col bg-gradient-to-br from-white to-orange-50">
                    <section className="flex-grow pb-8 sm:pb-16 lg:pt-16 lg:pb-16 bg-gradient-to-br from-white to-orange-50">
                        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div className="grid max-w-lg grid-cols-1 mx-auto lg:max-w-full lg:items-center lg:grid-cols-2 gap-y-8 lg:gap-x-16">
                                <div>
                                    <div className="text-center lg:text-left">
                                        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-orange-400">
                                            Magu Klowi Transport Services
                                        </h1>
                                        <p className="mt-4 sm:mt-6 text-base sm:text-lg text-black font-medium">
                                            Start your journey with ease through our all-inclusive car rental service, complete with a professional driver for every ride. Sit back, relax, and let us take you on the road to unforgettable moments.
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <Carousel />
                                </div>
                            </div>
                            <div className="flex justify-center mt-6 sm:mt-8 pt-12">
                                <button
                                    type="button"
                                    onClick={() => window.location.href='/Cars'}
                                    className="inline-flex justify-center items-center px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-xl font-bold text-white bg-orange-400 border border-transparent rounded-lg shadow-lg transform transition-transform duration-300 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 animate-bounce tracking-wide"
                                    style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
                                >
                                    BOOK NOW
                                </button>
                            </div>
                        </div>
                    </section>
                </div>

                {/* Strengths Section - Responsive Grid and Typography */}
                <motion.section 
                      variants={fadeIn}
                      className="py-12 sm:py-16 bg-orange-400"
                  >
                      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                          <h2 className="text-2xl sm:text-3xl font-bold text-white text-center mb-8 sm:mb-12">
                              Our Strengths
                          </h2>
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6">
                              {/* Strength 1 - Safety */}
                              <div className="bg-white/10 p-6 rounded-xl transform transition duration-300 hover:scale-105 hover:shadow-lg">
                                  <div className="flex flex-col items-center text-center">
                                      <i className="fas fa-user-shield text-4xl text-white mb-4"></i>
                                      <h3 className="text-xl font-semibold text-white mb-3">Safety First</h3>
                                      <p className="text-white/90">Our vehicles are equipped with the latest safety features to ensure a secure journey.</p>
                                  </div>
                              </div>

                              {/* Strength 2 - Punctuality */}
                              <div className="bg-white/10 p-6 rounded-xl transform transition duration-300 hover:scale-105 hover:shadow-lg">
                                  <div className="flex flex-col items-center text-center">
                                      <i className="fas fa-clock text-4xl text-white mb-4"></i>
                                      <h3 className="text-xl font-semibold text-white mb-3">Punctual Service</h3>
                                      <p className="text-white/90">We pride ourselves on timely arrivals and departures to keep your schedule on track.</p>
                                  </div>
                              </div>

                              {/* Strength 3 - Customer Support */}
                              <div className="bg-white/10 p-6 rounded-xl transform transition duration-300 hover:scale-105 hover:shadow-lg">
                                  <div className="flex flex-col items-center text-center">
                                      <i className="fas fa-headset text-4xl text-white mb-4"></i>
                                      <h3 className="text-xl font-semibold text-white mb-3">24/7 Customer Support</h3>
                                      <p className="text-white/90">Our support team is available around the clock to assist you with any inquiries or issues.</p>
                                  </div>
                              </div>

                              {/* Strength 4 - Variety of Vehicles */}
                              <div className="bg-white/10 p-6 rounded-xl transform transition duration-300 hover:scale-105 hover:shadow-lg">
                                  <div className="flex flex-col items-center text-center">
                                      <i className="fas fa-car text-4xl text-white mb-4"></i>
                                      <h3 className="text-xl font-semibold text-white mb-3">Diverse Vehicle Fleet</h3>
                                      <p className="text-white/90">Wide range of vehicles to meet various needs, from compact cars to luxury SUVs.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                </motion.section>

                {/* Fleet Section */}
                <motion.section 
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn}
                    className="py-12 sm:py-16 bg-gradient-to-br from-white to-orange-50"
                >
                    <Container maxWidth="lg">
                        <Typography 
                            variant="h2" 
                            className="text-2xl sm:text-4xl pb-4 sm:pb-6 font-bold text-orange-400 text-center mb-8 sm:mb-12 leading-tight"
                        >
                            Explore Our Fleet
                        </Typography>
                        
                        <Grid container spacing={4}>
                          {fleetData.map((car) => (
                            <Grid item xs={12} key={car.id}>
                              <motion.div
                                whileHover={{ 
                                  scale: 1.03,
                                  boxShadow: "0 10px 15px -3px rgba(0,0,0,0.1)"
                                }}
                                className="border-2 border-orange-200 rounded-xl overflow-hidden transition-all duration-300 bg-white"
                              >
                                <Card 
                                  elevation={0} 
                                  className="bg-transparent shadow-none"
                                >
                                  <CardContent className="p-0">
                                    <div className="grid md:grid-cols-3 gap-6 p-6">
                                      {/* Vehicle Image */}
                                      <div className="col-span-1 flex items-center justify-center border-b-2 md:border-b-0 md:border-r-2 border-orange-100 pr-6">
                                        <img
                                          src={car.image}
                                          alt={car.name}
                                          className="max-w-full max-h-72 object-contain rounded-lg transition-transform hover:scale-105"
                                        />
                                      </div>
                                      
                                      {/* Vehicle Details */}
                                      <div className="col-span-2 space-y-6">
                                        {/* Vehicle Name */}
                                        <div className="border-b-2 border-orange-100 pb-4">
                                          <Typography 
                                            variant="h4" 
                                            className="font-bold text-orange-900 text-2xl flex items-center"
                                          >
                                            <Star className="mr-3 text-orange-500" size={24} />
                                            {car.name}
                                          </Typography>
                                        </div>
                                        
                                        {/* Description */}
                                        <Typography 
                                          variant="body1" 
                                          className="text-gray-700 leading-relaxed"
                                        >
                                          {car.description}
                                        </Typography>
                                        
                                        {/* Vehicle Specifications */}
                                        <div className="grid md:grid-cols-3 gap-4">
                                          <div className="flex items-center text-orange-800">
                                            <Car className="mr-3 text-orange-600" size={20} />
                                            <Typography variant="body2">
                                              Seating: {car.capacity}
                                            </Typography>
                                          </div>
                                          <div className="flex items-center text-orange-800">
                                            <Navigation className="mr-3 text-orange-600" size={20} />
                                            <Typography variant="body2">
                                              Fuel: {car.fuelType}
                                            </Typography>
                                          </div>
                                          <div className="flex items-center text-orange-800">
                                            <Users className="mr-3 text-orange-600" size={20} />
                                            <Typography variant="body2">
                                              Transmission: {car.transmission}
                                            </Typography>
                                          </div>
                                        </div>
                                        
                                        {/* Vehicle Extras */}
                                        <div>
                                          <Typography 
                                            variant="subtitle1" 
                                            className="font-semibold text-orange-900 mb-3 flex items-center"
                                          >
                                            <Check className="mr-2 text-green-600" size={20} />
                                            Vehicle Extras
                                          </Typography>
                                          <Grid container spacing={2}>
                                            {car.extras.map((extra, index) => (
                                              <Grid item xs={12} sm={6} key={index}>
                                                <div className="flex items-center text-gray-700">
                                                  <Shield className="mr-2 text-orange-600" size={16} />
                                                  <Typography variant="body2">{extra}</Typography>
                                                </div>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                        
                                        {/* Safety Features */}
                                        <div>
                                          <Typography 
                                            variant="subtitle1" 
                                            className="font-semibold text-orange-900 mb-3 flex items-center"
                                          >
                                            <Shield className="mr-2 text-green-600" size={20} />
                                            Safety Features
                                          </Typography>
                                          <Grid container spacing={2}>
                                            {car.safetyFeatures.map((feature, index) => (
                                              <Grid item xs={12} sm={6} key={index}>
                                                <div className="flex items-center text-gray-700">
                                                  <Check className="mr-2 text-green-600" size={16} />
                                                  <Typography variant="body2">{feature}</Typography>
                                                </div>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </div>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                              </motion.div>
                            </Grid>
                          ))}
                        </Grid>
                        {/* New "Check More Vehicles" Button */}
                        <div className="flex justify-center mt-12">
                          <button
                            type="button"
                            onClick={() => window.location.href='/Cars'}
                            className="inline-flex justify-center items-center px-8 py-4 text-2xl font-extrabold text-white bg-orange-400 border border-transparent rounded-lg shadow-lg transform transition-transform duration-300 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 animate-bounce tracking-widest shadow-md"
                            style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}
                          >
                            CHECK MORE VEHICLES
                          </button>
                        </div>
                      </Container>
                </motion.section>

                {/* Testimonials Section */}
                <motion.section 
                  variants={fadeIn}
                  className="py-16 bg-white"
                >
                  <Container maxWidth="lg">
                    <Typography variant="h2" className="text-3xl font-bold text-orange-400 text-center mb-12">
                      Testimonials
                    </Typography>
                    <Grid container spacing={4}>
                      {[
                        {
                          text: "Excellent service and very reliable. Super bait ng driver at very professional, siguradong safe na safe ka sa byahe.",
                          author: "Kenneth Olpindo",
                          rating: 5
                        },
                        {
                          text: "I never knew na may ibang services pala sila and super sulit din talaga ng napili kong sasakyan kasi talaga namang napaka attentive ni kuyang driver. Will definitely rent again!",
                          author: "Kean Antonio",
                          rating: 4
                        },
                        {
                          text: "Great value for money. The booking process was smooth.",
                          author: "Sam Sebastian",
                          rating: 3.5
                        },
                        {
                          text: "The staff was very friendly and accommodating. Highly recommended!",
                          author: "Chris Leonardo",
                          rating: 5
                        }
                      ].map((testimonial, index) => (
                        <Grid item xs={12} sm={6} lg={3} key={index}>
                          <motion.div
                            whileHover={{ scale: 1.05 }}
                            className="bg-white p-6 rounded-lg shadow-md text-center h-full"
                          >
                            <Typography variant="body1" className="text-gray-600 mb-4">
                              "{testimonial.text}"
                            </Typography>
                            <div className="flex justify-center gap-1 mb-4">
                              {Array.from({ length: Math.floor(testimonial.rating) }).map((_, i) => (
                                <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                              ))}
                              {testimonial.rating % 1 !== 0 && (
                                <StarHalf className="w-5 h-5 text-yellow-400 fill-current" />
                              )}
                            </div>
                            <Typography variant="subtitle2" className="font-semibold">
                              - {testimonial.author}
                            </Typography>
                          </motion.div>
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                </motion.section>

                {/* FAQ Section */}
                <motion.section 
                  variants={fadeIn}
                  className="py-16 bg-gradient-to-br from-white to-orange-50"
                >
                  <Container maxWidth="lg">
                    <Typography variant="h2" className="text-3xl font-bold text-orange-400 text-center mb-12">
                      Frequently Asked Questions
                    </Typography>
                    <Box className="max-w-3xl mx-auto">
                      {[
                        {
                          question: "How can i book?",
                          answer: "You can book a vehicle through our website. Simply choose your desired vehicle, provide the necessary details, and confirm your booking."
                        },
                        {
                          question: "Are drivers included with every rental?",
                          answer: "Yes, all vehicle rentals come with a professional driver to ensure a safe and comfortable journey."
                        },
                        {
                          question: "What payment methods are accepted?",
                          answer: "We accept various payment methods, which can be selected during the booking process."
                        },
                        {
                          question: "What are the rental rates?",
                          answer: "Rental prices are determined based on the duration and destination of the rental. Rates may vary depending on the location."
                        }
                      ].map((faq, index) => (
                        <motion.div
                          key={index}
                          variants={fadeIn}
                          className="mb-8"
                        >
                          <Typography variant="h6" className="flex items-center gap-2 mb-2">
                            <HelpCircle className="w-5 h-5 text-orange-400" />
                            {faq.question}
                          </Typography>
                          <Typography variant="body1" className="text-gray-600 ml-7">
                            {faq.answer}
                          </Typography>
                        </motion.div>
                      ))}
                    </Box>
                  </Container>
                </motion.section>

                {/* Call to Action Section */}
                <section className="py-16 bg-white">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 text-center">
                        <h2 className="text-3xl font-bold text-orange-400">Ready to Hit the Road?</h2>
                        <p className="mt-4 text-lg pb-6 text-orange-400">Book your ride today and experience the difference!</p>
                        <button
                            type="button"
                            onClick={() => window.location.href='/Cars'}
                            className="inline-flex justify-center items-center px-8 py-4 mt-4 text-2xl font-extrabold text-white bg-orange-400 border border-transparent rounded-lg shadow-lg transform transition-transform duration-300 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400 animate-bounce tracking-widest shadow-md"
                            style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }} // Optional: for a text shadow effect
                        >
                            BOOK NOW
                        </button>
                    </div>
                </section>
            </motion.div>
        );
    }
}

export default HomePage;