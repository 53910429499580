import React from 'react';
import { Link } from 'react-router-dom';
import { Home, AlertTriangle } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-orange-50 to-orange-100 flex items-center justify-center px-4 py-8">
      <div className="max-w-md w-full bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="bg-orange-500 text-white text-center py-4">
          <h1 className="text-6xl font-bold">404</h1>
          <p className="text-xl">Page Not Found</p>
        </div>
        
        <div className="p-6 text-center space-y-6">
          <div className="flex justify-center mb-4">
            <AlertTriangle 
              className="h-24 w-24 text-orange-400" 
              strokeWidth={1.5}
            />
          </div>
          
          <h2 className="text-2xl font-semibold text-gray-800">
            Oops! Something went wrong
          </h2>
          
          <p className="text-gray-600 mb-4">
            The page you are looking for might have been removed, 
            had its name changed, or is temporarily unavailable.
          </p>
          
          <div className="flex justify-center space-x-4">
            <Link 
              to="/" 
              className="px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition duration-300 flex items-center space-x-2 shadow-md"
            >
              <Home className="h-5 w-5" />
              <span>Go to Home</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;