import React from 'react';
import Layout from '../../components/ManagementSide/Layout';

const AdminDashboard = () => {
  return (
    <div className="AdminDashboard">
      <Layout/>
    </div> 
  );
};

export default AdminDashboard;
