import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  EyeIcon, 
  TrashIcon, 
  UserIcon, 
  MailIcon, 
  PhoneIcon, 
  XIcon 
} from 'lucide-react';
import { collection, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { db } from '../../Firebase/firebaseConfig';

const UserManagementContent = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerIdCardUrl, setCustomerIdCardUrl] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleViewCustomer = async (customer) => {
    setSelectedCustomer(customer);
    
    if (customer.IdCard) {
      try {
        const storage = getStorage();
        const idCardRef = ref(storage, customer.IdCard);
        const downloadURL = await getDownloadURL(idCardRef);
        setCustomerIdCardUrl(downloadURL);
      } catch (err) {
        console.error("Error fetching ID card image:", err);
        setCustomerIdCardUrl(null);
      }
    } else {
      setCustomerIdCardUrl(null);
    }
  };

  const handleCloseOverlay = () => {
    setSelectedCustomer(null);
    setCustomerIdCardUrl(null);
  };

  const handleDeleteCustomer = async (customerId, idCardPath) => {
    try {
      if (idCardPath) {
        const storage = getStorage();
        const idCardRef = ref(storage, idCardPath);
        await deleteObject(idCardRef);
      }

      const customerRef = doc(db, 'Customers', customerId);
      await deleteDoc(customerRef);

      setCustomers(customers.filter(customer => customer.id !== customerId));
      setDeleteConfirmation(null);
    } catch (err) {
      console.error("Error deleting customer:", err);
      setError("Failed to delete customer. Please try again.");
    }
  };

  const confirmDeleteCustomer = (customer) => {
    setDeleteConfirmation(customer);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const q = query(
          collection(db, 'Customers'), 
          where('Role', '==', 'Customer')
        );

        const querySnapshot = await getDocs(q);
        
        const customersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setCustomers(customersList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching customers:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  if (loading) {
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex justify-center items-center h-full p-4"
      >
        <motion.div 
          animate={{ rotate: 360 }}
          transition={{ 
            repeat: Infinity, 
            duration: 1, 
            ease: "linear" 
          }}
          className="h-8 w-8 border-b-2 border-gray-900 rounded-full"
        />
        <span className="ml-2">Loading customers...</span>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" 
        role="alert"
      >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">Unable to fetch customer data.</span>
      </motion.div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="container mx-auto px-4 sm:px-8"
    >
      <div className="py-8">
        <motion.div 
          layout
          className="overflow-x-auto shadow rounded-lg"
        >
          <table className="min-w-full">
            <thead className="bg-gray-100">
              <tr>
                {['Name', 'Email', 'Contact', 'Actions'].map((header) => (
                  <th 
                    key={header} 
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <AnimatePresence>
                {customers.map((customer) => (
                  <motion.tr 
                    key={customer.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <UserIcon className="h-5 w-5 mr-2 text-gray-400" />
                        {`${customer.FirstName} ${customer.MiddleName || ''} ${customer.LastName}`}
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <MailIcon className="h-5 w-5 mr-2 text-gray-400" />
                        {customer.EmailAddress}
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <PhoneIcon className="h-5 w-5 mr-2 text-gray-400" />
                        {customer.ContactNumber}
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <div className="flex space-x-2">
                        <motion.button 
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => handleViewCustomer(customer)}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-xs flex items-center"
                        >
                          <EyeIcon className="h-4 w-4 mr-1" /> View
                        </motion.button>
                        <motion.button 
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => confirmDeleteCustomer(customer)}
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-xs flex items-center"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" /> Remove
                        </motion.button>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>

          {customers.length === 0 && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center py-4 text-gray-500"
            >
              No customers found.
            </motion.div>
          )}
        </motion.div>
      </div>

      {/* Customer Details Modal */}
      <AnimatePresence>
        {selectedCustomer && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4"
          >
            <motion.div 
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto"
            >
              <div className="flex justify-between items-center p-6 border-b">
                <h2 className="text-2xl font-bold text-gray-800">Customer Details</h2>
                <motion.button 
                  whileHover={{ rotate: 90 }}
                  onClick={handleCloseOverlay}
                  className="text-gray-600 hover:text-gray-900"
                >
                  <XIcon className="h-6 w-6" />
                </motion.button>
              </div>
              
              <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Personal Information */}
                <div>
                  <h3 className="text-lg font-semibold mb-4 border-b pb-2">Personal Information</h3>
                  <div className="space-y-2">
                    <p><strong>Full Name:</strong> {`${selectedCustomer.FirstName} ${selectedCustomer.MiddleName || ''} ${selectedCustomer.LastName}`}</p>
                    <p><strong>Customer ID:</strong> {selectedCustomer.CustomerID}</p>
                    <p><strong>Customer Type:</strong> {selectedCustomer.CustomerType}</p>
                    <p><strong>Total Rentals:</strong> {selectedCustomer.TotalRentals || 0}</p>
                  </div>
                </div>

                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-semibold mb-4 border-b pb-2">Contact Information</h3>
                  <div className="space-y-2">
                    <p><strong>Email:</strong> {selectedCustomer.EmailAddress}</p>
                    <p><strong>Contact Number:</strong> {selectedCustomer.ContactNumber}</p>
                    
                    <h4 className="font-semibold mt-4">Address</h4>
                    <p>
                      {selectedCustomer.Address?.Street}, {selectedCustomer.Address?.City}, 
                      {selectedCustomer.Address?.Province} {selectedCustomer.Address?.ZIP}
                    </p>
                  </div>
                </div>

                {/* ID Card */}
                {customerIdCardUrl && (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="md:col-span-2 text-center"
                  >
                    <h3 className="text-lg font-semibold mb-4 border-b pb-2">ID Card</h3>
                    <motion.img 
                      initial={{ scale: 0.9 }}
                      animate={{ scale: 1 }}
                      src={customerIdCardUrl} 
                      alt="Customer ID Card" 
                      className="max-w-full max-h-64 mx-auto object-contain rounded-lg shadow-md"
                    />
                  </motion.div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            <div className="p-6 text-center">
              <svg className="mx-auto mb-4 w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Are you sure you want to delete 
                <span className="font-bold ml-1">
                  {`${deleteConfirmation.FirstName} ${deleteConfirmation.LastName}`}
                </span>?
              </h3>
              <div className="flex justify-center space-x-4">
                <button 
                  onClick={() => handleDeleteCustomer(deleteConfirmation.id, deleteConfirmation.IdCard)}
                  className="bg-red-600 text-white hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                >
                  Yes, I'm sure
                </button>
                <button 
                  onClick={() => setDeleteConfirmation(null)}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default UserManagementContent;