import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  TbDashboard, 
  TbCalendarEvent, 
  TbCar, 
  TbTool, 
  TbUsers, 
  TbLogout,
  TbSearch,
  TbX,
} from 'react-icons/tb';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useClickAway } from 'react-use';
import { createPortal } from 'react-dom';

// Import content components
import DashboardContent from '../ManagementSide/DashboardContent';
import RentalsContent from '../ManagementSide/RentalsContent';
import VehicleManagementContent from '../ManagementSide/VehicleManagementContent';
import VehicleMaintenanceContent from '../ManagementSide/VehicleMaintenanceContent';
import UserManagementContent from '../ManagementSide/UserManagementContent';

const SidebarAndHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const db = getFirestore();

  // States
  const [activeSection, setActiveSection] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [adminData, setAdminData] = useState({
    fullName: '',
    role: 'Administrator',
    avatar: null
  });
  const [loading, setLoading] = useState(true);

  const BREAKPOINT = 768;
  const menuRef = React.useRef(null);
  useClickAway(menuRef, () => setShowUserMenu(false));

  // Navigation items with their corresponding components
  const navigationItems = useMemo(() => [
    { id: 'AdminDashboard', label: 'Dashboard', icon: TbDashboard, component: DashboardContent },
    { id: 'Rentals', label: 'Rentals', icon: TbCalendarEvent, component: RentalsContent },
    { id: 'VehicleList', label: 'Vehicle Management', icon: TbCar, component: VehicleManagementContent },
    { id: 'VehicleMaintenance', label: 'Vehicle Maintenance', icon: TbTool, component: VehicleMaintenanceContent },
    { id: 'UserList', label: 'User Management', icon: TbUsers, component: UserManagementContent },
  ], []);

    // Get current page title
    const getCurrentPageTitle = useCallback(() => {
      const activeItem = navigationItems.find(item => item.id === activeSection);
      return activeItem?.label || 'Dashboard';
    }, [navigationItems, activeSection]);

  // Get the active component based on activeSection
  const ActiveComponent = useMemo(() => {
    const activeItem = navigationItems.find(item => item.id === activeSection);
    return activeItem?.component || DashboardContent; // Default to Dashboard if no match
  }, [navigationItems, activeSection]);

    // Handle navigation item click
    const handleNavClick = (id) => {
      setActiveSection(id);
    };
  
  // Fetch admin data
  const fetchAdminData = useCallback(async (email) => {
    try {
      const q = query(collection(db, 'Customers'), where('EmailAddress', '==', email));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs[0].data();
        setAdminData({
          fullName: `${data.FirstName} ${data.LastName}`,
          role: data.Role || 'Administrator',
          avatar: data.Avatar
        });
      }
    } catch (error) {
      console.error('Error fetching admin data:', error);
    } finally {
      setLoading(false);
    }
  }, [db]);

  // Effects
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= BREAKPOINT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user?.email) {
      fetchAdminData(user.email);
    }
  }, [user?.email, fetchAdminData]);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    if (path) {
      setActiveSection(path);
    }
  }, [location]);

  // Handlers
  const handleLogout = async () => {
    try {
      await signOut();
      localStorage.clear();
      navigate('/SignIn', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Searching for:', searchQuery);
  };

  // Style getters
  const getNavItemStyle = (id) => {
    const isActive = activeSection === id;
    return `flex items-center w-full text-left px-4 py-3 rounded-lg transition-all duration-200 
      ${isActive 
        ? 'bg-yellow-500 text-gray-900 font-medium shadow-md' 
        : 'hover:bg-gray-700/50 text-gray-300 hover:text-white'}
      ${!isOpen && 'justify-center'}`;
  };

  // Components
  const UserAvatar = () => (
    <div className="w-8 h-8 rounded-full bg-yellow-500 flex items-center justify-center text-white font-medium">
      {adminData.fullName.charAt(0)}
    </div>
  );

  const SearchOverlay = () => createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-center pt-20">
      <div className="bg-white w-full max-w-2xl mx-4 rounded-lg shadow-xl">
        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            className="w-full px-4 py-3 pl-12 pr-10 rounded-lg focus:outline-none"
            autoFocus
          />
          <TbSearch className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
          <button
            type="button"
            onClick={() => setShowSearch(false)}
            className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <TbX className="w-5 h-5" />
          </button>
        </form>
      </div>
    </div>,
    document.body
  );

  return (
    <>
      {/* Sidebar */}
      <aside 
        className={`fixed top-0 left-0 h-screen bg-gray-800 shadow-xl flex flex-col 
          transition-all duration-300 ease-in-out z-40
          ${isOpen ? 'w-64' : 'w-20'}`}
      >
        {/* Logo Section */}
        <div className="p-4 border-b border-gray-700">
          <button 
            onClick={() => handleNavClick('AdminDashboard')} 
            className="flex items-center justify-center"
          >
            {isOpen ? (
              <h1 className="text-2xl font-bold text-yellow-500 tracking-wider">MKFLEET</h1>
            ) : (
              <h1 className="text-2xl font-bold text-yellow-500">MK</h1>
            )}
          </button>
        </div>

        {/* Navigation Items */}
        <nav className="flex-1 overflow-y-auto py-4 px-3">
          <ul className="space-y-2">
            {navigationItems.map(({ id, label, icon: Icon }) => (
              <li key={id}>
                <button 
                  onClick={() => handleNavClick(id)}
                  className={getNavItemStyle(id)}
                >
                  <Icon className={`w-6 h-6 ${!isOpen ? 'mx-auto' : 'mr-3'}`} />
                  {isOpen && <span className="truncate">{label}</span>}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </aside>

      {/* Header */}
      <header className={`bg-white border-b border-gray-200 fixed top-0 right-0 z-40 transition-all duration-300
        ${isOpen ? 'left-64' : 'left-20'}`}>
        <div className="px-6 py-4 flex items-center justify-between">
          {/* Left side */}
          <h1 className="text-xl font-semibold text-gray-800">
            {getCurrentPageTitle()}
          </h1>

          {/* Right side */}
          <div className="flex items-center space-x-4">

            {/* User Menu */}
            <div className="relative" ref={menuRef}>
              <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center space-x-3 p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <UserAvatar />
                <div className="hidden md:block text-left">
                  <p className="text-sm font-medium text-gray-700">
                    {loading ? 'Loading...' : adminData.fullName || 'Admin'}
                  </p>
                  <p className="text-xs text-gray-500">{adminData.role}</p>
                </div>
              </button>

              {/* User Menu Dropdown */}
              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-60 bg-white rounded-lg shadow-lg border border-gray-200 py-2">
                  <div className="px-4 py-3 border-b border-gray-100">
                    <p className="text-sm font-medium text-gray-700">{adminData.fullName}</p>
                    <p className="text-xs text-gray-500">{user?.email}</p>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="w-full px-4 py-2 text-sm text-left flex items-center space-x-3 hover:bg-gray-50 text-red-500 hover:bg-red-50"
                  >
                    <TbLogout className="w-5 h-5" />
                    <span>Log Out</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className={`transition-all duration-300 pt-20 pb-24 px-6
        ${isOpen ? 'ml-64' : 'ml-20'}`}>
        <ActiveComponent />
      </main>

      {/* Search Overlay */}
      {showSearch && <SearchOverlay />}
    </>
  );
};

export default SidebarAndHeader;