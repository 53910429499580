import React from 'react';
import { motion } from 'framer-motion';
import { 
    FaCar, 
    FaShuttleVan, 
    FaBus, 
    FaMapSigns, 
    FaLocationArrow, 
    FaShieldAlt,
    FaClock,
    FaCreditCard,
    FaFileAlt,
    FaUsers,
    FaTaxi,
    FaGlobeAmericas,
    FaBoxOpen,
    FaBriefcase
} from 'react-icons/fa';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
};

const Services = () => {
    return (
        <motion.div 
            className="bg-gray-50 pt-20"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            {/* New Services Section */}
            <motion.section 
                className="py-16 bg-gradient-to-br from-white to-orange-50"
                variants={containerVariants}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-900 text-center mb-8"
                        variants={itemVariants}
                    >
                        Our Services
                    </motion.h2>
                    <motion.div 
                        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                        variants={containerVariants}
                    >
                        {[
                            { Icon: FaTaxi, title: "Airport Transfers", description: "Hassle-free pick-up and drop-off to and from the airport." },
                            { Icon: FaGlobeAmericas, title: "City Tours", description: "Comfortable rides for sightseeing and exploring local attractions." },
                            { Icon: FaLocationArrow, title: "Point-to-Point Transfers", description: "Convenient rides from one location to another." },
                            { Icon: FaBriefcase, title: "Corporate Transport", description: "Professional transportation services for business trips or company events." },
                            { Icon: FaUsers, title: "Event Shuttle Services", description: "Reliable transport for weddings, conferences, or other gatherings." },
                            { Icon: FaCar, title: "Self-Drive & Chauffeur Rentals", description: "Flexible options with or without a professional driver." },
                            { Icon: FaBus, title: "Out-of-Town Trips", description: "Transport services for family vacations, team-building, or adventure getaways." },
                            { Icon: FaBoxOpen, title: "Parcel Delivery Services", description: "Fast and secure transportation for packages and goods." },
                            { Icon: FaMapSigns, title: "Customized Transport Packages", description: "Tailored services to meet unique customer needs." }
                        ].map(({ Icon, title, description }, index) => (
                            <motion.div 
                                key={title} 
                                className="bg-gray-50 p-6 rounded-lg shadow-md text-center"
                                variants={itemVariants}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Icon className="mx-auto text-6xl text-orange-400 mb-4"/>
                                <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
                                <p className="text-gray-600">{description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Rental Options Section (Modified) */}
            <motion.section 
                className="py-16 bg-white"
                variants={containerVariants}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-900 text-center"
                        variants={itemVariants}
                    >
                        Rental Options
                    </motion.h2>
                    <motion.div 
                        className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-4"
                        variants={containerVariants}
                    >
                        {[
                            { Icon: FaCar, title: "Daily Rentals", description: "With or without driver. Flexible for short-term needs." },
                            { Icon: FaShuttleVan, title: "Weekly Rentals", description: "Choose self-drive or chauffeur-driven options for week-long travel." },
                            { Icon: FaBus, title: "Monthly Rentals", description: "Extended rental packages with driver or self-drive options." },
                            { Icon: FaMapSigns, title: "Long-term Rentals", description: "Customizable long-term rental solutions with flexible driver arrangements." }
                        ].map(({ Icon, title, description }, index) => (
                            <motion.div 
                                key={title} 
                                className="text-center"
                                variants={itemVariants}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Icon className="mx-auto text-6xl text-orange-400"/>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">{title}</h3>
                                <p className="mt-2 text-gray-600">{description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            {/* Booking Process Section */}
            <motion.section 
                className="py-16 bg-gradient-to-br from-white to-orange-50"
                variants={containerVariants}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-900 text-center"
                        variants={itemVariants}
                    >
                        Booking Process
                    </motion.h2>
                    <motion.div 
                        className="mt-8 max-w-3xl mx-auto"
                        variants={containerVariants}
                    >
                        <motion.p 
                            className="mt-4 text-lg text-gray-600"
                            variants={itemVariants}
                        >
                            Follow these simple steps to book your vehicle rental:
                        </motion.p>
                        <motion.ul 
                            className="mt-4 text-gray-600 space-y-2"
                            variants={containerVariants}
                        >
                            {[
                                { Icon: FaLocationArrow, text: "Select your desired vehicle and rental duration." },
                                { Icon: FaUsers, text: "Provide your personal information and payment details." },
                                { Icon: FaFileAlt, text: "Submit the required documentation." },
                                { Icon: FaShieldAlt, text: "Confirm your booking and receive a confirmation email." }
                            ].map(({ Icon, text }, index) => (
                                <motion.li 
                                    key={text} 
                                    className="flex items-center space-x-3"
                                    variants={itemVariants}
                                >
                                    <Icon className="text-orange-400" />
                                    <span>{text}</span>
                                </motion.li>
                            ))}
                        </motion.ul>
                        <motion.div 
                            className="mt-8 text-center"
                            variants={itemVariants}
                        >
                            <motion.button
                                type="button"
                                onClick={() => window.location.href='/Cars'}
                                className="px-6 py-3 text-lg font-bold text-white bg-orange-400 border border-transparent rounded-md shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Book Now
                            </motion.button>
                        </motion.div>
                    </motion.div>
                </div>
            </motion.section>

            {/* Policies and Conditions Section */}
            <motion.section 
                className="py-16 bg-white"
                variants={containerVariants}
            >
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.h2 
                        className="text-3xl font-bold text-gray-900 text-center"
                        variants={itemVariants}
                    >
                        Policies and Conditions
                    </motion.h2>
                    <motion.div 
                        className="mt-8 max-w-3xl mx-auto"
                        variants={containerVariants}
                    >
                        {[
                            { Icon: FaClock, title: "Age Requirements", description: "Renters must be between 21 and 70 years old. Additional fees may apply for young or senior drivers." },
                            { Icon: FaCreditCard, title: "Late Fees", description: "Late returns may incur additional charges. Please contact us if you need to extend your rental period." },
                            { Icon: FaFileAlt, title: "Refund Policies", description: "Cancellations and changes to reservations are subject to our refund policies. Please review our terms and conditions for more details." },
                            { Icon: FaShieldAlt, title: "Insurance Coverage", description: "We offer various insurance options. Basic coverage is mandatory, but additional protection plans are available for your peace of mind." },
                            { Icon: FaLocationArrow, title: "Mileage Limits", description: "Mileage limits apply to all rentals. Excess mileage charges may be incurred for long-distance travel." },
                            { Icon: FaUsers, title: "Extra Driver Fees", description: "Additional drivers can be added to your rental agreement for a nominal fee. All drivers must meet our eligibility criteria." }
                        ].map(({ Icon, title, description }) => (
                            <motion.div 
                                key={title} 
                                className="mb-6 p-4 bg-gray-50 rounded-lg shadow-sm"
                                variants={itemVariants}
                                whileHover={{ scale: 1.02 }}
                            >
                                <div className="flex items-center mb-2">
                                    <Icon className="mr-3 text-orange-400" />
                                    <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                                </div>
                                <p className="text-gray-600">{description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default Services;