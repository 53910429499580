import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db, storage } from '../../Firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import zxcvbn from 'zxcvbn';

function SignUpComponent() {
    const [formData, setFormData] = useState({
        FirstName: '',
        MiddleName: '',
        LastName: '',
        ContactNumber: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        Address: {
            Province: '',
            City: '',
            Street: '',
            ZIP: ''
        },
        IdCard: null
    });
    const [errors, setErrors] = useState({});
    const [passwordStrength, setPasswordStrength] = useState({ score: 0, feedback: "" });
    const [previewImage, setPreviewImage] = useState(null);
    const [signUpError, setSignUpError] = useState("");
    const [showSuccessOverlay, setShowSuccessOverlay] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('Address.')) {
            const addressField = name.split('.')[1];
            setFormData(prev => ({
                ...prev,
                Address: {
                    ...prev.Address,
                    [addressField]: value
                }
            }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData(prev => ({ ...prev, IdCard: file }));
        
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setFormData(prev => ({ ...prev, Password: newPassword }));
        const result = zxcvbn(newPassword);
        setPasswordStrength({ 
            score: result.score, 
            feedback: result.feedback.suggestions.join(' ') 
        });
    };

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            'FirstName', 'LastName', 'ContactNumber', 'Email', 
            'Password', 'ConfirmPassword', 
            'Address.Province', 'Address.City', 'Address.Street'
        ];

        requiredFields.forEach(field => {
            const value = field.includes('.')
                ? formData.Address[field.split('.')[1]]
                : formData[field];
            
            if (!value) {
                newErrors[field] = "This field is required";
            }
        });

        // Password validation
        if (formData.Password !== formData.ConfirmPassword) {
            newErrors.ConfirmPassword = "Passwords do not match";
        }

        if (passwordStrength.score < 3) {
            newErrors.Password = "Password is too weak";
        }

        // ID Card validation
        if (!formData.IdCard) {
            newErrors.IdCard = "Please upload an ID card";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }

        try {
            // Firebase signup logic (similar to original component)
            const userCredential = await createUserWithEmailAndPassword(
                auth, 
                formData.Email, 
                formData.Password
            );
            const user = userCredential.user;

            // ID Card upload
            let idCardUrl = "";
            if (formData.IdCard) {
                const storageRef = ref(storage, `IdCards/${user.uid}`);
                await uploadBytes(storageRef, formData.IdCard);
                idCardUrl = await getDownloadURL(storageRef);
            }

            // Add user to Firestore
            await addDoc(collection(db, 'Customers'), {
                FirstName: formData.FirstName,
                MiddleName: formData.MiddleName || "",
                LastName: formData.LastName,
                ContactNumber: formData.ContactNumber,
                EmailAddress: formData.Email,
                Address: formData.Address,
                IdCard: idCardUrl,
                DateRegistered: new Date(),
                TotalRentals: 0,
                Role: 'Customer'
            });

            // Show success overlay
            setShowSuccessOverlay(true);

            // Redirect to sign in after 3 seconds
            setTimeout(() => {
                navigate('/SignIn');
            }, 3000);

        } catch (error) {
            console.error("Signup error:", error);
            setSignUpError("Registration failed. Please try again.");
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-100 via-orange-200 to-orange-300 flex items-center justify-center p-4">
            <div className="bg-white shadow-2xl rounded-2xl w-full max-w-4xl grid grid-cols-1 md:grid-cols-2 overflow-hidden">
                {/* Left Side - Branding & Description */}
                <div className="bg-orange-600 text-white p-8 flex flex-col justify-center space-y-6 hidden md:block">
                    <h1 className="text-4xl font-bold">Welcome to MKFLEET</h1>
                    <p className="text-lg">
                        Create your account and start your journey with our vehicle rental platform. 
                        Quick, secure, and easy sign-up process awaits you.
                    </p>
                    <div className="space-y-2">
                        <div className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-orange-200" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                            <span>Quick Registration</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <svg className="w-6 h-6 text-orange-200" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                            <span>Secure Data Protection</span>
                        </div>
                    </div>
                </div>

                {/* Right Side - Sign Up Form */}
                <div className="p-8 space-y-6">
                    <h2 className="text-3xl font-bold text-orange-600 text-center">Sign Up</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    name="FirstName"
                                    value={formData.FirstName}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.FirstName ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.FirstName && <p className="text-red-500 text-xs mt-1">{errors.FirstName}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    name="LastName"
                                    value={formData.LastName}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.LastName ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.LastName && <p className="text-red-500 text-xs mt-1">{errors.LastName}</p>}
                            </div>
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.Email ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.Email && <p className="text-red-500 text-xs mt-1">{errors.Email}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Contact Number</label>
                                <input
                                    type="tel"
                                    name="ContactNumber"
                                    value={formData.ContactNumber}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.ContactNumber ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.ContactNumber && <p className="text-red-500 text-xs mt-1">{errors.ContactNumber}</p>}
                            </div>
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Province</label>
                                <input
                                    type="text"
                                    name="Address.Province"
                                    value={formData.Address.Province}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors['Address.Province'] ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors['Address.Province'] && <p className="text-red-500 text-xs mt-1">{errors['Address.Province']}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">City</label>
                                <input
                                    type="text"
                                    name="Address.City"
                                    value={formData.Address.City}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors['Address.City'] ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors['Address.City'] && <p className="text-red-500 text-xs mt-1">{errors['Address.City']}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Street & Barangay</label>
                            <input
                                type="text"
                                name="Address.Street"
                                value={formData.Address.Street}
                                onChange={handleChange}
                                className={`mt-1 block w-full rounded-md border ${errors['Address.Street'] ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                            />
                            {errors['Address.Street'] && <p className="text-red-500 text-xs mt-1">{errors['Address.Street']}</p>}
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    type="password"
                                    name="Password"
                                    value={formData.Password}
                                    onChange={handlePasswordChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.Password ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.Password && <p className="text-red-500 text-xs mt-1">{errors.Password}</p>}
                                {passwordStrength.score > 0 && (
                                    <div className="mt-1">
                                        <div 
                                            className={`h-1 rounded-full ${
                                                passwordStrength.score < 2 ? 'bg-red-500' : 
                                                passwordStrength.score < 3 ? 'bg-yellow-500' : 
                                                'bg-green-500'
                                            }`}
                                            style={{ width: `${(passwordStrength.score + 1) * 20}%` }}
                                        />
                                        <p className="text-xs text-gray-600 mt-1">{passwordStrength.feedback}</p>
                                    </div>
                                )}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                                <input
                                    type="password"
                                    name="ConfirmPassword"
                                    value={formData.ConfirmPassword}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full rounded-md border ${errors.ConfirmPassword ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                                />
                                {errors.ConfirmPassword && <p className="text-red-500 text-xs mt-1">{errors.ConfirmPassword}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Upload ID Card</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className={`mt-1 block w-full rounded-md border ${errors.IdCard ? 'border-red-500' : 'border-gray-300'} shadow-sm py-2 px-3`}
                            />
                            {errors.IdCard && <p className="text-red-500 text-xs mt-1">{errors.IdCard}</p>}
                            
                            {previewImage && (
                                <div className="mt-4 flex justify-center">
                                    <img 
                                        src={previewImage} 
                                        alt="ID Card Preview" 
                                        className="h-40 w-auto object-cover rounded-md shadow-md"
                                    />
                                </div>
                            )}
                        </div>

                        {signUpError && (
                            <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{signUpError}</span>
                            </div>
                        )}

                        <div>
                            <button
                                type="submit"
                                className="w-full bg-orange-600 text-white py-3 rounded-md hover:bg-orange-700 transition duration-300 ease-in-out transform hover:scale-101 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                            >
                                Create Account
                            </button>
                        </div>

                        {/* Success Overlay */}
                        {showSuccessOverlay && (
                            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white p-8 rounded-2xl shadow-2xl text-center max-w-md">
                                    <svg 
                                        className="mx-auto mb-4 w-24 h-24 text-green-500" 
                                        fill="currentColor" 
                                        viewBox="0 0 20 20"
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" 
                                            clipRule="evenodd" 
                                        />
                                    </svg>
                                    <h2 className="text-3xl font-bold text-gray-800 mb-4">Account Created!</h2>
                                    <p className="text-gray-600 mb-6">
                                        Your account has been successfully registered. 
                                        You will be redirected to the sign-in page shortly.
                                    </p>
                                    <div className="w-full bg-green-500 h-1 animate-shrink"></div>
                                </div>
                            </div>
                        )}

                        <div className="text-center">
                            <p className="text-sm text-gray-600">
                                Already have an account?{' '}
                                <a 
                                    href="/SignIn" 
                                    className="text-orange-600 hover:text-orange-800 font-medium"
                                >
                                    Sign In
                                </a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignUpComponent;