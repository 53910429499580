import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaCarAlt, FaEdit, FaBan } from 'react-icons/fa';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  X, 
  Car, 
  Palette, 
  Calendar, 
  Clock, 
  MapPin, 
  Fuel, 
  Wrench, 
  CloudSun 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const UserProfile = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [rentals, setRentals] = useState([]);
  const [selectedRental, setSelectedRental] = useState(null);
  const [cancelConfirmation, setCancelConfirmation] = useState(null);
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        if (user?.email) {
          // Fetch Customer Data
          const customerQuery = query(collection(db, 'Customers'), where('EmailAddress', '==', user.email));
          const customerSnapshot = await getDocs(customerQuery);

          // Fetch Rental Data
          const rentalQuery = query(collection(db, 'Rentals'), where('customer.EmailAddress', '==', user.email));
          const rentalSnapshot = await getDocs(rentalQuery);

          if (!customerSnapshot.empty) {
            const customerDoc = customerSnapshot.docs[0];
            setUserData(customerDoc.data());
            setFormData(customerDoc.data());
          }

          // Set Rentals
          const rentalDocs = rentalSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setRentals(rentalDocs);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user, db]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setFormData(userData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Changing:', name, 'to', value);
    
    // Handle nested address fields
    if (name.startsWith('Address.')) {
      const addressKey = name.split('.')[1];
      setFormData((prevData) => ({
        ...prevData,
        Address: {
          ...prevData.Address,
          [addressKey]: value
        }
      }));
    } else {
      // Handle regular top-level fields
      setFormData((prevData) => ({ 
        ...prevData, 
        [name]: value 
      }));
    }
  };

  const handleSaveChanges = async () => {
    console.log('Saving form data:', formData);
    try {
      if (user?.email) {
        const q = query(collection(db, 'Customers'), where('EmailAddress', '==', user.email));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const customerDoc = querySnapshot.docs[0];
          const customerRef = doc(db, 'Customers', customerDoc.id);
          
          // Ensure the entire formData object is saved, including nested Address
          await updateDoc(customerRef, {
            ...formData,
            Address: {
              Street: formData.Address?.Street || '',
              City: formData.Address?.City || '',
              Province: formData.Address?.Province || '',
              ZIP: formData.Address?.ZIP || ''
            }
          });
          
          setUserData(formData);
          setMessage('Changes applied successfully!');
          setEditMode(false);
        }
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      setMessage('Failed to update profile. Please try again.');
    }
  };

  // Loading Spinner Component
  const LoadingSpinner = () => (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="flex flex-col items-center text-black space-y-4">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-orange-500"></div>
        <span className="text-2xl">Loading profile...</span>
      </div>
    </div>
  );

  // Car Details Modal
  const DetailRow = ({ icon: Icon, label, value }) => (
    <div className="flex items-center space-x-3 bg-gray-50 p-3 rounded-lg">
      <div className="text-blue-600">
        <Icon className="w-5 h-5 flex-shrink-0" />
      </div>
      <div className="overflow-hidden">
        <p className="text-xs text-gray-500 truncate">{label}</p>
        <p className="font-semibold text-gray-800 truncate">{value}</p>
      </div>
    </div>
  );
  
  const CarDetailsModal = ({ rental, onClose }) => {
      if (!rental) return null;
  
      // Calculate rental days
      const calculateRentalDays = (startDate, endDate) => {
        if (startDate === endDate) return 'Same Day';
        return `${rental.totalDays} days`;
      };
  
      return (
        <AnimatePresence>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 sm:p-4"
          >
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
              className="bg-white rounded-2xl w-full max-w-5xl max-h-[95vh] overflow-y-auto shadow-2xl"
            >
              {/* Header */}
              <div className="p-4 sm:p-6 bg-gray-100 flex justify-between items-center border-b border-gray-200">
                <div className="flex items-center space-x-3 overflow-hidden">
                  <Car className="w-6 h-6 sm:w-8 sm:h-8 text-blue-600 flex-shrink-0" />
                  <h2 className="text-lg sm:text-2xl font-bold text-gray-800 truncate">
                    {rental.car.make} {rental.car.model} Details
                  </h2>
                </div>
                <button 
                  onClick={onClose} 
                  className="text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <X className="w-6 h-6 sm:w-8 sm:h-8" />
                </button>
              </div>
                
              {/* Content Container */}
              <div className="flex flex-col sm:grid sm:grid-cols-3 gap-4 sm:gap-8 p-4 sm:p-8">
                {/* Car Image - Full width on mobile, 2 columns on desktop */}
                <div className="sm:col-span-2 order-1 sm:order-none">
                  <div className="relative w-full pt-[56%] rounded-2xl overflow-hidden shadow-lg">
                    <img 
                      src={rental.car.imageUrl} 
                      alt={`${rental.car.make} ${rental.car.model}`} 
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                </div>
                  
                {/* Car Details - Full width on mobile, 1 column on desktop */}
                <div className="sm:col-span-1 order-2 sm:order-none">
                  <div className="grid grid-cols-1 sm:grid-cols-1 gap-2 sm:gap-4">
                    <DetailRow icon={Car} label="Make" value={rental.car.make} />
                    <DetailRow icon={Car} label="Model" value={rental.car.model} />
                    <DetailRow icon={Palette} label="Color" value={rental.car.color} />
                    <DetailRow icon={Calendar} label="Year" value={rental.car.year} />
                    <DetailRow icon={Fuel} label="Fuel Type" value={rental.car.fuelType} />
                    <DetailRow icon={Wrench} label="Transmission" value={rental.car.transmissionType} />
                  </div>
                </div>
              </div>
                
              {/* Rental Information */}
              <div className="p-4 sm:p-8 bg-gray-50 border-t">
                <h3 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 flex items-center space-x-3">
                  <MapPin className="w-5 h-5 sm:w-6 sm:h-6 text-blue-600" />
                  <span>Rental Information</span>
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
                  <DetailRow icon={MapPin} label="Pickup Location" value={rental.pickupLocation} />
                  <DetailRow icon={MapPin} label="Dropoff Location" value={rental.dropoffLocation} />
                  <DetailRow icon={Calendar} label="Start Date" value={rental.startDate} />
                  <DetailRow icon={Calendar} label="End Date" value={rental.endDate} />
                  <DetailRow icon={Clock} label="Pickup Time" value={rental.pickupTime} />
                  <DetailRow 
                    icon={CloudSun} 
                    label="Total Rental Days" 
                    value={calculateRentalDays(rental.startDate, rental.endDate)} 
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      );
  };

  // No Bookings View Component
  const NoBookingsView = () => {
    return (
      <div className="bg-white shadow-md rounded-lg p-8 text-center mt-8">
        <FaCarAlt className="mx-auto text-6xl text-gray-300 mb-4" />
        <h3 className="text-xl font-semibold text-gray-700 mb-4">
          No Current Bookings
        </h3>
        <p className="text-gray-500 mb-6">
          Looks like you haven't booked any vehicles yet. Start your journey today!
        </p>
        <button
          onClick={() => navigate('/cars')}
          className="px-6 py-3 bg-blue-600 text-white text-base rounded-md hover:bg-blue-700 transition-colors"
        >
          Book a Car Now
        </button>
      </div>
    );
  };

  const handleRentalCancellation = async (rentalId) => {
    try {
      const rentalRef = doc(db, 'Rentals', rentalId);
      
      // Update rental status to 'Cancelled'
      await updateDoc(rentalRef, {
        status: 'Cancelled'
      });

      // Update local state
      setRentals(prevRentals => 
        prevRentals.map(rental => 
          rental.id === rentalId ? { ...rental, status: 'Cancelled' } : rental
        )
      );

      // Close confirmation modal
      setCancelConfirmation(null);

      // Show success message
      setMessage('Rental successfully cancelled.');
    } catch (error) {
      console.error('Error cancelling rental:', error);
      setMessage('Failed to cancel rental. Please try again.');
    }
  };

  const CancellationConfirmModal = ({ rental, onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg max-w-md w-full p-6">
          <div className="text-center">
            <FaBan className="mx-auto text-5xl text-red-500 mb-4" />
            <h2 className="text-xl font-bold text-gray-800 mb-4">Cancel Rental</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to cancel your rental for the {rental.car.make} {rental.car.model}?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={onCancel}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                No, Keep Rental
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Yes, Cancel Rental
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // If loading, show spinner
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={`max-w-4xl mx-auto p-8 pb-16 pt-16 sm:pt-8 md:pt-32 lg:pt-32 ${editMode ? 'bg-blue-50/30' : ''}`}>
      <div className="flex items-center justify-between">
        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-8 text-gray-700">
          User Profile
        </h2>
        {editMode && (
          <div className="flex items-center space-x-2 mb-8 text-blue-600">
            <FaEdit className="w-5 h-5" />
            <span className="text-sm font-medium">Editing Mode</span>
          </div>
        )}
      </div>
      
      {userData && (
        <>
          <div className={`mb-6 transition-all duration-300 ${editMode ? 'opacity-50' : 'opacity-100'}`}>
            <p className="text-base sm:text-lg md:text-xl font-bold text-gray-700">{`${userData.FirstName} ${userData.LastName}`}</p>
            <p className="text-sm sm:text-base text-gray-500">
              Member since {new Date(userData.DateRegistered?.toDate()).toLocaleDateString()}
            </p>
            <p className="text-sm sm:text-base text-gray-500">
              {`${userData.Address.City}, ${userData.Address.Province}, ${userData.Address.Street}, ${userData.Address.ZIP}`}
            </p>
          </div>

          {message && <p className="text-green-500 mb-4 text-sm sm:text-base">{message}</p>}

          <form className={`space-y-4 sm:space-y-6 transition-all duration-300 ${editMode ? 'ring-4 ring-blue-200 p-4 rounded-lg' : ''}`}>
            {/* Name Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  First Name
                </label>
                <input
                  type="text"
                  name="FirstName"
                  value={formData.FirstName}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  Last Name
                </label>
                <input
                  type="text"
                  name="LastName"
                  value={formData.LastName}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
            </div>

            {/* Contact Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  Email Address
                </label>
                <div className="mt-1 relative">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaEnvelope className="text-gray-400" />
                  </span>
                  <input
                    type="email"
                    value={formData.EmailAddress}
                    className="pl-10 p-2 w-full border border-gray-300 rounded-md text-sm sm:text-base"
                    readOnly
                  />
                </div>
              </div>

              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  Phone Number
                </label>
                <input
                  type="text"
                  name="ContactNumber"
                  value={formData.ContactNumber}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
            </div>

            {/* Address Fields */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  Street
                </label>
                <input
                  type="text"
                  name="Address.Street"
                  value={formData.Address?.Street || ''}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  City
                </label>
                <input
                  type="text"
                  name="Address.City"
                  value={formData.Address?.City || ''}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  Province
                </label>
                <input
                  type="text"
                  name="Address.Province"
                  value={formData.Address?.Province || ''}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
              <div>
                <label className={`block text-sm sm:text-base font-medium ${editMode ? 'text-blue-700' : 'text-gray-700'}`}>
                  ZIP Code
                </label>
                <input
                  type="text"
                  name="Address.ZIP"
                  value={formData.Address?.ZIP || ''}
                  onChange={handleInputChange}
                  className={`mt-1 p-2 w-full border rounded-md text-sm sm:text-base 
                    ${editMode 
                      ? 'border-blue-300 bg-blue-50 focus:ring-2 focus:ring-blue-500' 
                      : 'border-gray-300'
                    }`}
                  readOnly={!editMode}
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4">
              {editMode ? (
                <>
                  <button
                    type="button"
                    className="px-3 py-2 border border-gray-300 text-gray-700 text-sm sm:text-base rounded-md"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="px-3 py-2 bg-blue-600 text-white text-sm sm:text-base rounded-md"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="px-3 py-2 bg-blue-600 text-white text-sm sm:text-base rounded-md"
                  onClick={handleEditClick}
                >
                  Edit
                </button>
              )}
            </div>
          </form>

          {/* Vehicle Rental Bookings Table */}
          <div className="mt-8">
            <h3 className="text-lg sm:text-xl font-semibold mb-4 text-gray-700">
              Current Vehicle Bookings
            </h3>
            
            {rentals.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider">Vehicle</th>
                      <th className="px-4 py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                      <th className="px-4 py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider">Pickup Location</th>
                      <th className="px-4 py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="px-4 py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {rentals.map((rental) => (
                      <tr 
                        key={rental.id} 
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => setSelectedRental(rental)}
                      >
                        <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base text-gray-900">
                          {rental.car.make} {rental.car.model}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base text-gray-900">
                          {rental.startDate}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm sm:text-base text-gray-900">
                          {rental.pickupLocation}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <span className={`
                            px-2 py-1 rounded font-bold 
                            ${
                              rental.status.toLowerCase() === 'active' 
                                ? 'bg-green-100 text-green-800' 
                                : rental.status.toLowerCase() === 'completed'
                                ? 'bg-blue-100 text-blue-800'
                                : rental.status.toLowerCase() === 'pending'
                                ? 'bg-yellow-100 text-yellow-800'
                                : rental.status.toLowerCase() === 'cancelled'
                                ? 'bg-red-100 text-red-800'
                                : 'bg-gray-100 text-gray-800'
                            }`}>
                            {rental.status.toUpperCase()}
                          </span>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
                          {rental.status.toLowerCase() === 'pending' ? (
                            <button
                              onClick={() => setCancelConfirmation(rental)}
                              className="px-2 py-1 bg-red-100 text-red-800 rounded hover:bg-red-200 transition-colors"
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              disabled
                              className="px-2 py-1 bg-gray-100 text-gray-400 rounded cursor-not-allowed opacity-50"
                              title="Cancellation not available"
                            >
                              Cancel
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoBookingsView />
            )}
          </div>

          {/* Car Details Modal */}
          {selectedRental && (
            <CarDetailsModal 
              rental={selectedRental} 
              onClose={() => setSelectedRental(null)} 
            />
          )}

          {/* Cancellation Confirmation Modal */}
          {cancelConfirmation && (
            <CancellationConfirmModal 
              rental={cancelConfirmation}
              onConfirm={() => handleRentalCancellation(cancelConfirmation.id)}
              onCancel={() => setCancelConfirmation(null)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserProfile;