import React, { useState } from 'react';
import { 
  getAuth, 
  sendPasswordResetEmail,
  confirmPasswordReset 
} from 'firebase/auth';
import { Lock, Mail, Check } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Added for routing
import logo from '../../Assets/mkfleetlogo.png'; // Adjust the import path as needed

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [stage, setStage] = useState('email'); // Possible stages: 'email', 'code', 'reset'
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const auth = getAuth();

  const handleSendResetEmail = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError('Please enter a valid email address');
        return;
      }

      // Send password reset email directly
      await sendPasswordResetEmail(auth, email);
      setStage('code');
      setSuccess('Reset code sent to your email. Check your inbox.');
    } catch (error) {
      console.error('Password reset error:', error); // Log full error for debugging
      
      // More comprehensive error handling
      switch (error.code) {
        case 'auth/invalid-email':
          setError('Invalid email address');
          break;
        case 'auth/user-not-found':
          setError('No account found with this email address');
          break;
        case 'auth/too-many-requests':
          setError('Too many requests. Please try again later.');
          break;
        case 'auth/network-request-failed':
          setError('Network error. Please check your connection.');
          break;
        default:
          setError('An error occurred. Please try again.');
      }
    }
  };

  const handleVerifyResetCode = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      // In a real implementation, you would verify the code here
      // This is a placeholder for actual code verification
      setStage('reset');
      setSuccess('Enter your new password');
    } catch (error) {
      setError(error.message);
      setStage('email');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }

    try {
      // Note: In a real app, you'd use the actual reset code from Firebase
      await confirmPasswordReset(auth, resetCode, newPassword);
      setSuccess('Password reset successfully');
      setStage('email');
    } catch (error) {
      setError(error.message);
    }
  };

  const renderSignInLink = () => (
    <div className="text-center mt-4">
      <Link 
        to="/signin" 
        className="text-orange-600 hover:text-orange-800 hover:underline transition duration-300 ease-in-out"
      >
        Click here to Sign In
      </Link>
    </div>
  );

  const renderEmailStage = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md px-4 sm:px-0"
    >
      <div className="mb-8 text-center">
        <img 
          src={logo} 
          alt="MK Fleet Logo" 
          className="mx-auto h-20 w-auto mb-4"
        />
        <h2 className="text-2xl font-bold text-orange-700">Forgot Password</h2>
      </div>
      <form onSubmit={handleSendResetEmail} className="space-y-4">
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-400" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="w-full pl-10 pr-4 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 bg-orange-50"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-orange-600 text-white py-2 rounded-md hover:bg-orange-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Send Reset Code
        </button>
      </form>
      {renderSignInLink()}
    </motion.div>
  );

  const renderCodeStage = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md px-4 sm:px-0"
    >
      <div className="mb-8 text-center">
        <img 
          src={logo} 
          alt="MK Fleet Logo" 
          className="mx-auto h-20 w-auto mb-4"
        />
        <h2 className="text-2xl font-bold text-orange-700">Verify Reset Code</h2>
      </div>
      <form onSubmit={handleVerifyResetCode} className="space-y-4">
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-400" />
          <input
            type="text"
            value={resetCode}
            onChange={(e) => setResetCode(e.target.value)}
            placeholder="Enter reset code"
            required
            className="w-full pl-10 pr-4 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 bg-orange-50"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-orange-600 text-white py-2 rounded-md hover:bg-orange-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Verify Code
        </button>
      </form>
      {renderSignInLink()}
    </motion.div>
  );

  const renderResetStage = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md px-4 sm:px-0"
    >
      <div className="mb-8 text-center">
        <img 
          src={logo} 
          alt="MK Fleet Logo" 
          className="mx-auto h-20 w-auto mb-4"
        />
        <h2 className="text-2xl font-bold text-orange-700">Reset Password</h2>
      </div>
      <form onSubmit={handleResetPassword} className="space-y-4">
        <div className="relative">
          <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-orange-400" />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            required
            className="w-full pl-10 pr-4 py-2 border border-orange-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 bg-orange-50"
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-orange-600 text-white py-2 rounded-md hover:bg-orange-700 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Reset Password
        </button>
      </form>
      {renderSignInLink()}
    </motion.div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-orange-100 p-4">
      <div className="w-full max-w-md">
        {error && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-orange-200 border border-orange-400 text-orange-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            {error}
          </motion.div>
        )}
        
        {success && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 flex items-center"
            role="alert"
          >
            <Check className="mr-2" />
            {success}
          </motion.div>
        )}

        {stage === 'email' && renderEmailStage()}
        {stage === 'code' && renderCodeStage()}
        {stage === 'reset' && renderResetStage()}
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;