import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Mail, Lock, Eye, EyeOff } from 'lucide-react';

function SignInComponent() {
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [signInError, setSignInError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signIn, currentUser } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  // Character limit constants
  const MAX_EMAIL_LENGTH = 100;
  const MAX_PASSWORD_LENGTH = 50;

  useEffect(() => {
    const checkUser = async () => {
      if (currentUser) {
        const userQuery = query(collection(db, 'Customers'), where('Email', '==', currentUser.email));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          const { Role } = userDoc.data();

          if (Role === 'Admin') {
            navigate('/AdminDashboard', { replace: true });
          } else if (Role === 'Customer') {
            navigate('/Cars', { replace: true });
          }
        }
      }
    };

    checkUser();
  }, [currentUser, navigate, db]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_EMAIL_LENGTH) {
      setSignInEmail(value);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_PASSWORD_LENGTH) {
      setSignInPassword(value);
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    
    // Additional validation before submission
    if (signInEmail.length > MAX_EMAIL_LENGTH) {
      setSignInError(`Email cannot exceed ${MAX_EMAIL_LENGTH} characters`);
      return;
    }

    if (signInPassword.length > MAX_PASSWORD_LENGTH) {
      setSignInError(`Password cannot exceed ${MAX_PASSWORD_LENGTH} characters`);
      return;
    }

    setLoading(true);
    setSignInError("");

    try {
      // First, check if the email exists in the Customers collection
      const userQuery = query(collection(db, 'Customers'), where('EmailAddress', '==', signInEmail));
      const userSnapshot = await getDocs(userQuery);

      // If no user found in Customers collection, prevent authentication
      if (userSnapshot.empty) {
        setSignInError("No registered user found with this email.");
        setLoading(false);
        return;
      }

      // If user exists, proceed with authentication
      await signIn(signInEmail, signInPassword);

      // After successful authentication, get user role
      const userDoc = userSnapshot.docs[0];
      const { Role } = userDoc.data();

      // Navigate based on user role
      if (Role === 'Admin') {
        navigate('/AdminDashboard', { replace: true });
      } else if (Role === 'Customer') {
        navigate('/Cars', { replace: true });
      } else {
        setSignInError("User role is not recognized.");
      }
    } catch (error) {
      // Handle authentication errors
      if (error.code === 'auth/wrong-password') {
        setSignInError("Incorrect password. Please try again.");
      } else if (error.code === 'auth/user-not-found') {
        setSignInError("No user found with this email.");
      } else {
        setSignInError("Sign-in failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  const inputVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.4 } }
  };

  return (
    <section className="bg-gray-900 min-h-screen flex flex-col items-center justify-center px-4 py-10 sm:px-6 lg:px-8 sm:py-16 lg:py-24">
      <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto bg-white rounded-lg p-8 shadow-md hover:shadow-lg transition-shadow duration-300">
        <h2 className="text-3xl font-bold leading-tight text-orange-500 text-center">
          <a href="/" className="text-orange-500 hover:no-underline">MKFLEET</a>
        </h2>

        <form onSubmit={handleSignIn} className="mt-6 space-y-5">
          <motion.div
            variants={inputVariants}
            initial="initial"
            animate="animate"
          >
            <div>
              <label htmlFor="email" className="text-base font-medium text-gray-900">
                Email address 
                <span className="text-xs text-gray-500 ml-2">
                  ({signInEmail.length}/{MAX_EMAIL_LENGTH})
                </span>
              </label>
              <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Mail className="w-5 h-5 text-orange-400" />
                </div>
                <input
                  type="email"
                  id="email"
                  value={signInEmail}
                  onChange={handleEmailChange}
                  maxLength={MAX_EMAIL_LENGTH}
                  placeholder="Enter email to sign in"
                  className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-orange-500 focus:bg-white caret-orange-500"
                  required
                />
              </div>
            </div>
          </motion.div>

          <motion.div
            variants={inputVariants}
            initial="initial"
            animate="animate"
          >
            <div>
              <label htmlFor="password" className="text-base font-medium text-gray-900">
                Password 
                <span className="text-xs text-gray-500 ml-2">
                  ({signInPassword.length}/{MAX_PASSWORD_LENGTH})
                </span>
              </label>
              <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Lock className="w-5 h-5 text-orange-400" />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={signInPassword}
                  onChange={handlePasswordChange}
                  maxLength={MAX_PASSWORD_LENGTH}
                  placeholder="Enter your password"
                  className="block w-full py-4 pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-orange-500 focus:bg-white caret-orange-500"
                  required
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <EyeOff className="w-5 h-5 text-gray-500" aria-label="Hide password" />
                  ) : (
                    <Eye className="w-5 h-5 text-gray-500" aria-label="Show password" />
                  )}
                </div>
              </div>
              <div className="flex justify-end mt-2 text-sm text-orange-500 hover:text-orange-600 font-medium cursor-pointer">
                <a href="/ForgotPassword">Forgot Password?</a>
              </div>
            </div>
          </motion.div>

          {signInError && (
            <div className="text-red-600 mt-3" role="alert" aria-live="assertive">
              {signInError}
            </div>
          )}

          <button
            type="submit"
            className={`w-full py-4 px-4 text-white bg-orange-500 rounded-md focus:outline-none hover:bg-orange-700 transition-all duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Signing In...' : 'Sign In'}
          </button>

          <motion.div 
            className="text-center mt-5"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
          >
            <p className="text-sm sm:text-base text-gray-600">
              Don't have an account yet?{' '}
              <motion.a
                href="/SignUp"
                className="text-orange-500 hover:text-orange-600 font-medium"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign Up
              </motion.a>
            </p>
          </motion.div>
        </form>
      </div>
    </section>
  );
}

export default SignInComponent;