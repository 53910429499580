import React, { useState, useEffect } from 'react';
import { 
  Car, 
  CurrencyIcon, 
  ShoppingCart, 
  TrendingUp, 
  BarChart2, 
  PieChart as PieChartIcon,
  CalendarCheck,
  TrendingUp as TrendingUpIcon 
} from 'lucide-react';
import { motion } from 'framer-motion';
import { 
  collection, 
  query, 
  getDocs, 
} from 'firebase/firestore';
import { db } from '../../Firebase/firebaseConfig'; // Adjust the import path
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer, 
  LineChart, 
  Line, 
  PieChart, 
  Pie, 
  Cell 
} from 'recharts';

const DashboardContent = () => {
  const [dashboardStats, setDashboardStats] = useState({
    totalVehicles: 0,
    totalBookings: 0,
    totalRevenue: 0,
    rentalsToday: 0,
    monthlyRevenueComparison: {
      currentMonthRevenue: 0,
      previousMonthRevenue: 0,
      percentageChange: 0
    }
  });

  const [chartData, setChartData] = useState({
    revenueByMonth: [],
    paymentTypes: [],
    rentalStatuses: []
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Get current date at start of day
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Fetch total vehicles
        const vehiclesRef = collection(db, 'Vehicles');
        const vehiclesSnap = await getDocs(vehiclesRef);
        const totalVehicles = vehiclesSnap.size;

        // Fetch total bookings and revenue
        const rentalsRef = collection(db, 'Rentals');
        const rentalsQuery = query(rentalsRef);
        const rentalsSnap = await getDocs(rentalsQuery);
        
        const totalBookings = rentalsSnap.size;
        const totalRevenue = rentalsSnap.docs.reduce((sum, doc) => 
          sum + (doc.data().paymentAmount || 0), 0);

        // Count rentals for today
        const rentalsToday = rentalsSnap.docs.filter(doc => {
          const bookingDate = doc.data().bookingDate?.toDate();
          return bookingDate && 
                 bookingDate.getFullYear() === today.getFullYear() &&
                 bookingDate.getMonth() === today.getMonth() &&
                 bookingDate.getDate() === today.getDate();
        }).length;

        // Process monthly revenue
        const monthlyRevenueMap = new Map();
        
        rentalsSnap.docs.forEach(doc => {
          const bookingDate = doc.data().bookingDate?.toDate();
          const paymentAmount = doc.data().paymentAmount || 0;
          
          if (bookingDate) {
            const monthKey = bookingDate.toLocaleString('default', { month: 'short' });
            const existingRevenue = monthlyRevenueMap.get(monthKey) || 0;
            monthlyRevenueMap.set(monthKey, existingRevenue + paymentAmount);
          }
        });

        // Calculate current and previous month's revenue
        const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const currentMonth = today.toLocaleString('default', { month: 'short' });
        const currentMonthIndex = monthOrder.indexOf(currentMonth);
        const previousMonthIndex = currentMonthIndex > 0 ? currentMonthIndex - 1 : 11;
        const previousMonth = monthOrder[previousMonthIndex];

        const currentMonthRevenue = monthlyRevenueMap.get(currentMonth) || 0;
        const previousMonthRevenue = monthlyRevenueMap.get(previousMonth) || 0;

        // Calculate percentage change
        const percentageChange = previousMonthRevenue > 0
          ? ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue * 100)
          : 0;

        // Convert map to sorted array
        const revenueByMonth = monthOrder
          .map(month => ({
            month, 
            revenue: monthlyRevenueMap.get(month) || 0
          }))
          .filter(item => item.revenue > 0);

        // Process payment types
        const paymentTypesMap = new Map();
        rentalsSnap.docs.forEach(doc => {
          const paymentMethod = doc.data().paymentMethod || 'Unknown';
          const paymentAmount = doc.data().paymentAmount || 0;
          
          const existingAmount = paymentTypesMap.get(paymentMethod) || 0;
          paymentTypesMap.set(paymentMethod, existingAmount + paymentAmount);
        });

        // Convert payment types map to array
        const paymentTypes = Array.from(paymentTypesMap).map(([name, value]) => ({
          name,
          value
        }));

        // Process rental statuses (case-insensitive)
        const rentalStatusesMap = new Map();
        const statusOptions = ['Active', 'Pending', 'Completed', 'Cancelled'];

        rentalsSnap.docs.forEach(doc => {
          const status = (doc.data().status || 'Unknown').toLowerCase();
          
          statusOptions.forEach(opt => {
            if (status === opt.toLowerCase()) {
              const existingCount = rentalStatusesMap.get(opt) || 0;
              rentalStatusesMap.set(opt, existingCount + 1);
            }
          });
        });

        // Convert rental statuses map to array
        const rentalStatuses = statusOptions.map(status => ({
          name: status,
          count: rentalStatusesMap.get(status) || 0
        }));

        // Update stats
        setDashboardStats(prevStats => ({
          ...prevStats,
          totalVehicles,
          totalBookings,
          totalRevenue: Number(totalRevenue.toFixed(2)),
          rentalsToday,
          monthlyRevenueComparison: {
            currentMonthRevenue: Number(currentMonthRevenue.toFixed(2)),
            previousMonthRevenue: Number(previousMonthRevenue.toFixed(2)),
            percentageChange: Number(percentageChange.toFixed(2))
          }
        }));

        // Update chart data
        setChartData(prevData => ({
          ...prevData,
          revenueByMonth,
          paymentTypes,
          rentalStatuses
        }));

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  // Color palette for charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RevenueComparisonBadge = ({ percentageChange }) => {
    const isPositive = percentageChange >= 0;
    return (
      <div className={`flex items-center text-xs ${isPositive ? 'text-green-600' : 'text-red-600'}`}>
        {isPositive ? (
          <TrendingUpIcon className="mr-1 w-4 h-4" />
        ) : (
          <TrendingUpIcon className="mr-1 w-4 h-4 transform rotate-180" />
        )}
        {Math.abs(percentageChange)}% {isPositive ? 'increase' : 'decrease'}
      </div>
    );
  };

  return (
    <div className="p-4 sm:p-6 bg-gray-50 min-h-screen">
      {/* KPI Cards - Responsive Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 mb-6 sm:mb-8">
        {[
          { 
            icon: <Car className="text-blue-500 w-6 h-6 sm:w-8 sm:h-8" />, 
            title: 'Total Vehicles', 
            value: dashboardStats.totalVehicles 
          },
          { 
            icon: <ShoppingCart className="text-green-500 w-6 h-6 sm:w-8 sm:h-8" />, 
            title: 'Total Bookings', 
            value: dashboardStats.totalBookings 
          },
          { 
            icon: <CurrencyIcon className="text-purple-500 w-6 h-6 sm:w-8 sm:h-8" />, 
            title: 'Total Revenue', 
            value: `₱${dashboardStats.totalRevenue.toLocaleString()}`,
            comparison: (
              <RevenueComparisonBadge 
                percentageChange={dashboardStats.monthlyRevenueComparison.percentageChange} 
              />
            )
          },
          { 
            icon: <CalendarCheck className="text-orange-500 w-6 h-6 sm:w-8 sm:h-8" />, 
            title: 'Rentals Today', 
            value: dashboardStats.rentalsToday 
          }
        ].map((card, index) => (
          <motion.div 
            key={card.title}
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ delay: index * 0.2 }}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col"
          >
            <div className="flex items-center">
              <div className="mr-3 sm:mr-4">{card.icon}</div>
              <div>
                <h3 className="text-xs sm:text-sm text-gray-600">{card.title}</h3>
                <p className="text-base sm:text-2xl font-bold">{card.value}</p>
              </div>
            </div>
            {card.comparison && (
              <div className="mt-2">
                {card.comparison}
              </div>
            )}
          </motion.div>
        ))}
      </div>

      {/* Charts Section - Responsive Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        {/* Revenue Line Chart */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-3 sm:mb-4">
            <TrendingUp className="mr-2 text-blue-500 w-5 h-5 sm:w-6 sm:h-6" />
            <h3 className="text-base sm:text-lg font-semibold">Monthly Revenue</h3>
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart data={chartData.revenueByMonth}>
              <XAxis 
                dataKey="month" 
                tick={{ fontSize: 10 }}
              />
              <YAxis 
                tick={{ fontSize: 10 }}
              />
              <Tooltip 
                contentStyle={{ fontSize: 12 }}
                formatter={(value) => [`₱${value.toLocaleString()}`, 'Revenue']}
              />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Payment Types Pie Chart */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-3 sm:mb-4">
            <PieChartIcon className="mr-2 text-green-500 w-5 h-5 sm:w-6 sm:h-6" />
            <h3 className="text-base sm:text-lg font-semibold">Payment Types</h3>
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={chartData.paymentTypes}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius="80%"
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                labelStyle={{ fontSize: 10 }}
              >
                {chartData.paymentTypes.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ fontSize: 12 }}
                formatter={(value) => [`₱${value.toLocaleString()}`, 'Total Amount']}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Rental Statuses Bar Chart */}
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md col-span-1 md:col-span-2 lg:col-span-1">
          <div className="flex items-center mb-3 sm:mb-4">
            <BarChart2 className="mr-2 text-purple-500 w-5 h-5 sm:w-6 sm:h-6" />
            <h3 className="text-base sm:text-lg font-semibold">Rental Statuses</h3>
          </div>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={chartData.rentalStatuses}>
              <XAxis 
                dataKey="name" 
                tick={{ fontSize: 10 }}
              />
              <YAxis 
                tick={{ fontSize: 10 }}
              />
              <Tooltip 
                contentStyle={{ fontSize: 12 }}
              />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;