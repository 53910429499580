import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Plus, Car, CheckCircle, Calendar, Wrench } from 'lucide-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from '@mui/material';
import { Delete as DeleteIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../Firebase/firebaseConfig';
import { FormControl, InputLabel } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';

// Styled components
const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const StatsCard = ({ title, value, icon: Icon, color, bgColor }) => (
  <motion.div
    initial={{ scale: 0.9, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="h-full"
  >
    <div className="bg-white rounded-lg shadow-md p-6 h-full hover:shadow-lg transition-all duration-200 hover:-translate-y-1">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-600 text-sm font-medium mb-1">{title}</p>
          <p className={`text-2xl font-bold ${color}`}>{value}</p>
        </div>
        <div className={`${bgColor} p-3 rounded-full`}>
          <Icon className={`w-6 h-6 ${color}`} />
        </div>
      </div>
    </div>
  </motion.div>
);

const VehicleManagementContent = () => {
  // State management
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [stats, setStats] = useState({
    total: 0,
    Available: 0,
    Booked: 0,
    Maintenance: 0
  });
  
  const [imageUpload, setImageUpload] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [vehicleForm, setVehicleForm] = useState({
    name: '',
    make: '',
    model: '',
    status: 'Available',
    carImage: '',
    color: '',
    driver: '',
    fuelType: '',
    insuranceExpiryDate: '',
    insurancePolicyNumber: '',
    lastMaintenance: '',
    licensePlate: '',
    nextMaintenance: '',
    notes: '',
    odometer: '',
    rentalRate: '',
    seatNumber: '',
    transmissionType: '',
    vin: '',
    vehicleId: '',
    year: ''
  });

  const filteredVehicles = vehicles.filter(vehicle => 
    vehicle.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    vehicle.make?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    vehicle.model?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUpload(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    const storage = getStorage();
    const imageRef = ref(storage, `carImages/${Date.now()}-${file.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const fetchVehicles = useCallback(async () => {
    try {
      setLoading(true);
      const vehiclesCollection = collection(db, 'Vehicles');
      const vehiclesSnapshot = await getDocs(vehiclesCollection);
      const vehiclesList = vehiclesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setVehicles(vehiclesList);
      updateStats(vehiclesList);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
      setAlert({
        open: true,
        message: 'Error fetching vehicles: ' + error.message,
        severity: 'error'
      });
      setVehicles([]);
      updateStats([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateStats = (vehiclesList) => {
    const stats = vehiclesList.reduce((acc, vehicle) => ({
      total: acc.total + 1,
      Available: acc.Available + (vehicle.status === 'Available' ? 1 : 0),
      Booked: acc.Booked + (vehicle.status === 'Booked' ? 1 : 0),
      Maintenance: acc.Maintenance + (vehicle.status === 'Maintenance' ? 1 : 0)
    }), { total: 0, Available: 0, Booked: 0, Maintenance: 0 });
    setStats(stats);
  };

  const handleSaveVehicle = async () => {
    if (!validateForm()) return;

    try {
      let imageUrl = vehicleForm.carImage;
      if (imageUpload) {
        if (dialogMode === 'edit' && vehicleForm.carImage) {
          const storage = getStorage();
          const oldImageRef = ref(storage, vehicleForm.carImage);
          try {
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error("Error deleting old image:", error);
          }
        }
        imageUrl = await uploadImage(imageUpload);
      }

      if (dialogMode === 'add') {
        const vehiclesRef = collection(db, 'Vehicles');
        const newVehicleId = uuidv4();
        await addDoc(vehiclesRef, {
          ...vehicleForm,
          vehicleId: newVehicleId,
          carImage: imageUrl
        });
        setAlert({
          open: true,
          message: 'Vehicle added successfully',
          severity: 'success'
        });
      } else {
        const vehicleRef = doc(db, 'Vehicles', selectedVehicle.id);
        await updateDoc(vehicleRef, {
          ...vehicleForm,
          carImage: imageUrl
        });
        setAlert({
          open: true,
          message: 'Vehicle updated successfully',
          severity: 'success'
        });
      }

      handleCloseDialog();
      fetchVehicles();
    } catch (error) {
      setAlert({
        open: true,
        message: `Error ${dialogMode === 'add' ? 'adding' : 'updating'} vehicle: ${error.message}`,
        severity: 'error'
      });
    }
  };

  const validateForm = () => {
    const required = ['name', 'make', 'model'];
    const missing = required.filter(field => !vehicleForm[field]);
    if (missing.length > 0) {
      setAlert({
        open: true,
        message: `Please fill in the following fields: ${missing.join(', ')}`,
        severity: 'error'
      });
      return false;
    }
    return true;
  };

  const handleDeleteVehicle = async (id) => {
    if (window.confirm('Are you sure you want to delete this vehicle?')) {
      try {
        const vehicleRef = doc(db, 'Vehicles', id);
        await deleteDoc(vehicleRef);
        setAlert({
          open: true,
          message: 'Vehicle deleted successfully',
          severity: 'success'
        });
        fetchVehicles();
      } catch (error) {
        setAlert({
          open: true,
          message: 'Error deleting vehicle: ' + error.message,
          severity: 'error'
        });
      }
    }
  };

  const handleOpenDialog = (mode, vehicle = null) => {
    setDialogMode(mode);
    setSelectedVehicle(vehicle);
    if (vehicle) {
      setVehicleForm(vehicle);
    } else {
      setVehicleForm({
        name: '',
        make: '',
        model: '',
        status: 'Available',
        carImage: '',
        color: '',
        driver: '',
        fuelType: '',
        insuranceExpiryDate: '',
        insurancePolicyNumber: '',
        lastMaintenance: '',
        licensePlate: '',
        nextMaintenance: '',
        notes: '',
        odometer: '',
        rentalRate: '',
        seatNumber: '',
        transmissionType: '',
        vin: '',
        vehicleId: '',
        year: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedVehicle(null);
    setImageUpload(null);
    setImagePreview('');
  };

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles]);

  return (
    <Box sx={{ p: 3 }}>
      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="col-span-1">
          <StatsCard 
            title="Total Vehicles" 
            value={stats.total} 
            icon={Car}
            color="text-blue-600"
            bgColor="bg-blue-50"
          />
        </div>
        <div className="col-span-1">
          <StatsCard 
            title="Available" 
            value={stats.Available} 
            icon={CheckCircle}
            color="text-green-600"
            bgColor="bg-green-50"
          />
        </div>
        <div className="col-span-1">
          <StatsCard 
            title="Booked" 
            value={stats.Booked} 
            icon={Calendar}
            color="text-red-600"
            bgColor="bg-red-50"
          />
        </div>
        <div className="col-span-1">
          <StatsCard 
            title="Maintenance" 
            value={stats.Maintenance || 0} 
            icon={Wrench}
            color="text-amber-600"
            bgColor="bg-amber-50"
          />
        </div>
      </div>

      {/* Search and Add Section */}
    <div className="flex flex-col md:flex-row gap-4 mb-6">
      {/* Search Bar */}
      <div className="flex-grow relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          placeholder="Search vehicles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white text-sm placeholder-gray-400 focus:outline-none transition-colors duration-200"
        />
      </div>

      {/* Add Button */}
      <button
        onClick={() => handleOpenDialog('add')}
        className="flex items-center justify-center px-4 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200 min-w-[140px]"
      >
        <Plus className="h-5 w-5 mr-2" />
        Add Vehicle
      </button>
    </div>

      {/* Vehicles Table */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md overflow-hidden"
      >
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Make
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Model
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <AnimatePresence>
                  {filteredVehicles.map((vehicle) => (
                    <motion.tr
                      key={vehicle.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="hover:bg-gray-50"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{vehicle.name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{vehicle.make}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{vehicle.model}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`inline-flex px-2 py-1 text-xs font-semibold rounded-full
                          ${vehicle.status === 'Available' 
                            ? 'bg-green-100 text-green-800'
                            : vehicle.status === 'Maintenance'
                            ? 'bg-amber-100 text-amber-800'
                            : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {vehicle.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handleOpenDialog('edit', vehicle)}
                          className="text-blue-600 hover:text-blue-900 mr-4 focus:outline-none"
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDeleteVehicle(vehicle.id)}
                          className="text-red-600 hover:text-red-900 focus:outline-none"
                        >
                          <svg
                            className="h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td>
                    </motion.tr>
                  ))}
                </AnimatePresence>
              </tbody>
            </table>
          </div>
        )}
      </motion.div>

      {/* Vehicle Form Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {dialogMode === 'add' ? 'Add New Vehicle' : 'Edit Vehicle'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Image Upload Section */}
            <Grid item xs={12}>
              <Box sx={{ textAlign: 'center', mb: 2 }}>
                {(imagePreview || vehicleForm.carImage) ? (
                  <Box position="relative" display="inline-block">
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      src={imagePreview || vehicleForm.carImage}
                      alt="Vehicle preview"
                      style={{ maxHeight: 200, borderRadius: 8 }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper'
                      }}
                      onClick={() => {
                        setImagePreview('');
                        setImageUpload(null);
                        setVehicleForm({ ...vehicleForm, carImage: '' });
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mb: 2 }}
                  >
                    Upload Image
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </Button>
                )}
              </Box>
            </Grid>

            {/* Basic Details */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vehicle Name"
                value={vehicleForm.name}
                onChange={(e) => setVehicleForm({ ...vehicleForm, name: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vehicle ID"
                value={vehicleForm.vehicleId}
                InputProps={{
                  readOnly: true, // Set the input to read-only
                }}
                helperText="Vehicle ID is automatically generated." // Add a helper text message
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Make"
                value={vehicleForm.make}
                onChange={(e) => setVehicleForm({ ...vehicleForm, make: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Model"
                value={vehicleForm.model}
                onChange={(e) => setVehicleForm({ ...vehicleForm, model: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Year"
                type="number"
                value={vehicleForm.year}
                onChange={(e) => setVehicleForm({ ...vehicleForm, year: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Color"
                value={vehicleForm.color}
                onChange={(e) => setVehicleForm({ ...vehicleForm, color: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={vehicleForm.status}
                  onChange={(e) => setVehicleForm({ ...vehicleForm, status: e.target.value })}
                >
                  <MenuItem value="Available">Available</MenuItem>
                  <MenuItem value="Booked">Booked</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="License Plate"
                value={vehicleForm.licensePlate}
                onChange={(e) => setVehicleForm({ ...vehicleForm, licensePlate: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="VIN"
                value={vehicleForm.vin}
                onChange={(e) => setVehicleForm({ ...vehicleForm, vin: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fuel Type"
                value={vehicleForm.fuelType}
                onChange={(e) => setVehicleForm({ ...vehicleForm, fuelType: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Transmission Type"
                value={vehicleForm.transmissionType}
                onChange={(e) => setVehicleForm({ ...vehicleForm, transmissionType: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Seats"
                type="number"
                value={vehicleForm.seatNumber}
                onChange={(e) => setVehicleForm({ ...vehicleForm, seatNumber: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Rental Rate"
                type="number"
                value={vehicleForm.rentalRate}
                onChange={(e) => setVehicleForm({ ...vehicleForm, rentalRate: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Odometer Reading"
                type="number"
                value={vehicleForm.odometer}
                onChange={(e) => setVehicleForm({ ...vehicleForm, odometer: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Assigned Driver"
                value={vehicleForm.driver}
                onChange={(e) => setVehicleForm({ ...vehicleForm, driver: e.target.value })}
              />
            </Grid>

            {/* Maintenance and Insurance */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Maintenance Date"
                type="date"
                value={vehicleForm.lastMaintenance}
                onChange={(e) => setVehicleForm({ ...vehicleForm, lastMaintenance: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Next Maintenance Date"
                type="date"
                value={vehicleForm.nextMaintenance}
                onChange={(e) => setVehicleForm({ ...vehicleForm, nextMaintenance: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Insurance Policy Number"
                value={vehicleForm.insurancePolicyNumber}
                onChange={(e) => setVehicleForm({ ...vehicleForm, insurancePolicyNumber: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Insurance Expiry Date"
                type="date"
                value={vehicleForm.insuranceExpiryDate}
                onChange={(e) => setVehicleForm({ ...vehicleForm, insuranceExpiryDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Notes"
                value={vehicleForm.notes}
                onChange={(e) => setVehicleForm({ ...vehicleForm, notes: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSaveVehicle}
          >
            {dialogMode === 'add' ? 'Add Vehicle' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setAlert({ ...alert, open: false })} 
          severity={alert.severity}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VehicleManagementContent;