import React from 'react'
import Header from '../../components/CustomerSide/Header';
import UserProfile from '../../components/CustomerSide/UserProfile';
import Footer from '../../components/CustomerSide/Footer';

const Profile = () => {
  return (
    <div className="Services">
        <Header />
            <UserProfile />
        <Footer />
    </div>
  )
}

export default Profile;
