import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

// Importing images from the Assets folder
import carousel1 from '../../Assets/CarouselImages/carousel1.png';
import carousel2 from '../../Assets/CarouselImages/carousel2.png';
import carousel3 from '../../Assets/CarouselImages/carousel3.png';
import carousel4 from '../../Assets/CarouselImages/carousel4.png';
import carousel5 from '../../Assets/CarouselImages/carousel5.png';

const Carousel = () => {
    const images = useMemo(() => [
        carousel1,
        carousel2,
        carousel3,
        carousel4,
        carousel5,
    ], []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState(0);

    // Slide transition variants with side-by-side movement
    const slideVariants = {
        initial: (direction) => ({
            x: direction > 0 ? '100%' : '-100%',
            opacity: 0,
            scale: 0.8
        }),
        enter: {
            zIndex: 1,
            x: 0,
            opacity: 1,
            scale: 1
        },
        exit: (direction) => ({
            zIndex: 0,
            x: direction < 0 ? '100%' : '-100%',
            opacity: 0,
            scale: 0.8
        })
    };

    const slideTransition = {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut"
    };

    // Memoized navigation functions
    const goToSlide = useCallback((index) => {
        const direction = index > currentIndex ? 1 : -1;
        setDirection(direction);
        setCurrentIndex(index);
    }, [currentIndex]);

    const goToPrevious = useCallback(() => {
        setDirection(-1);
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    }, [images.length]);

    const goToNext = useCallback(() => {
        setDirection(1);
        setCurrentIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    }, [images.length]);

    // Auto-advance slides
    useEffect(() => {
        const interval = setInterval(goToNext, 5000);
        return () => clearInterval(interval);
    }, [goToNext]);

    // Keyboard navigation
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') goToNext();
            if (e.key === 'ArrowLeft') goToPrevious();
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [goToNext, goToPrevious]);

    // Preload next and previous images for smoother transitions
    const preloadIndex = (index) => (index + 1) % images.length;
    const prevLoadIndex = (index) => (index - 1 + images.length) % images.length;

    return (
        <div 
            className="relative overflow-hidden rounded-lg mx-auto w-full max-w-[800px] h-[400px] group"
            aria-roledescription="carousel"
            aria-label="Image Carousel"
        >
            <div className="relative w-full h-full overflow-hidden">
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={currentIndex}
                        className="absolute w-full h-full"
                        custom={direction}
                        variants={slideVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        transition={slideTransition}
                    >
                        <img
                            src={images[currentIndex]}
                            alt={`Slide ${currentIndex + 1}`}
                            className="w-full h-full object-contain rounded-lg"
                        />
                    </motion.div>

                    {/* Preload next and previous images */}
                    <img 
                        key={`preload-next-${currentIndex}`}
                        src={images[preloadIndex(currentIndex)]} 
                        alt="Preload next" 
                        className="hidden" 
                    />
                    <img 
                        key={`preload-previous-${currentIndex}`}
                        src={images[prevLoadIndex(currentIndex)]} 
                        alt="Preload previous" 
                        className="hidden" 
                    />
                </AnimatePresence>
            </div>

            {/* Navigation Arrows */}
            <button
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/40 p-2 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
                onClick={goToPrevious}
                aria-label="Previous slide"
            >
                <ChevronLeft className="w-6 h-6" />
            </button>
            <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/40 p-2 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
                onClick={goToNext}
                aria-label="Next slide"
            >
                <ChevronRight className="w-6 h-6" />
            </button>

            {/* Dots Navigation */}
            <div 
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 bg-black/30 px-4 py-2 rounded-full z-10"
                role="tablist"
            >
                {images.map((_, index) => (
                    <motion.button
                        key={`carousel-indicator-${index}`}  // Use a unique string key
                        className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                            currentIndex === index ? 'bg-orange-400' : 'bg-gray-300'
                        }`}
                        onClick={() => goToSlide(index)}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        role="tab"
                        aria-selected={currentIndex === index}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Carousel;