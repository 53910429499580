import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../Firebase/firebaseConfig'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import SignInComponent from '../../components/CustomerSide/SignInComponent'

const SignIn = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      const currentUser = auth.currentUser

      if (currentUser) {
        try {
          // Fetch user document from Firestore Customers collection
          const userDocRef = doc(db, 'Customers', currentUser.uid)
          const userDocSnap = await getDoc(userDocRef)

          if (userDocSnap.exists()) {
            const userRole = userDocSnap.data().role

            // Redirect based on user role
            switch (userRole) {
              case 'Admin':
                navigate('/AdminDashboard')
                break
              case 'Customer':
                navigate('/')
                break
              default:
                // Optional: handle unexpected roles
                console.log('Unrecognized user role')
            }
          }
        } catch (error) {
          console.error('Error checking user role:', error)
        }
      }
    }

    // Perform immediate check
    checkAuthAndRedirect()

    // Set up listener for any subsequent auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await checkAuthAndRedirect()
      }
    })

    // Cleanup subscription on component unmount
    return () => unsubscribe()
  }, [navigate])

  return (
    <div className='SignIn'>
      <SignInComponent />
    </div>
  )
}

export default SignIn