import React from 'react';
import SignUpComponent from '../../components/CustomerSide/SignUpComponent';

const SignUp = () => {
  return (
    <div className='SignUp'>
      <SignUpComponent />
    </div>
    
  )
}

export default SignUp;