import React from 'react';
import { motion } from 'framer-motion';
import { 
  Car, 
  Target, 
  Leaf, 
  CheckCircle,
} from 'lucide-react';
import Logo from '../../Assets/mkfleetlogo.png';

const AboutPage = () => {
  const sectionVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="bg-[#FFF5E1] min-h-screen">
      {/* Hero Section */}
      <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariants}
        className="bg-[#FF7F3F] text-white py-24 text-center"
      >
        <div className="max-w-4xl mx-auto px-4 pt-12">
          <img 
            src={Logo} 
            alt="Magu-Klowi Fleet Logo" 
            className="mx-auto mb-6 h-44 w-auto" 
          />
          <motion.h1 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-5xl font-bold mb-6"
          >
            Magu-Klowi Vehicle Rentals
          </motion.h1>
          <p className="text-xl max-w-2xl mx-auto text-white/90">
            Driving Your Journey, Empowering Your Adventures Since 2022
          </p>
        </div>
      </motion.section>

      {/* Company Overview */}
      <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariants}
        className="py-16 bg-white"
      >
        <div className="max-w-7xl mx-auto px-4 grid md:grid-cols-2 gap-10 items-center">
          <div>
            <h2 className="text-3xl font-bold text-[#FF7F3F] mb-6">Who We Are</h2>
            <p className="text-gray-700 leading-relaxed">
              Magu-Klowi Vehicle Rentals is more than just a rental service. We're your trusted partner in mobility, offering a seamless and personalized experience that goes beyond simply renting a vehicle.
            </p>
          </div>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            className="flex justify-center"
          >
            <Car size={200} className="text-[#FF7F3F] opacity-70" />
          </motion.div>
        </div>
      </motion.section>

      {/* Core Values */}
      <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariants}
        className="py-16 bg-gradient-to-br from-white to-orange-50"
      >
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-[#FF7F3F] text-center mb-12">Our Core Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: Target, title: "Precision", description: "Delivering exact solutions tailored to your needs." },
              { icon: CheckCircle, title: "Reliability", description: "Consistent, dependable service you can trust." },
              { icon: Leaf, title: "Sustainability", description: "Committed to environmentally responsible practices." }
            ].map(({ icon: Icon, title, description }, index) => (
              <motion.div 
                key={title}
                whileHover={{ scale: 1.05 }}
                className="bg-white rounded-xl p-6 text-center shadow-lg hover:shadow-xl transition-all"
              >
                <div className="flex justify-center mb-4">
                  <Icon size={50} className="text-[#FF7F3F]" />
                </div>
                <h3 className="text-xl font-bold text-[#FF7F3F] mb-3">{title}</h3>
                <p className="text-gray-600">{description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Leadership Team */}
      <motion.section 
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={sectionVariants}
        className="py-16 bg-white"
      >
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-[#FF7F3F] text-center mb-12">Meet Our Leaders</h2>
          <div className="grid md:grid-cols-2 gap-10">
            <motion.div 
              whileHover={{ scale: 1.05 }}
              className="bg-gradient-to-br from-white to-orange-50 rounded-xl p-8 text-center shadow-lg"
            >
              <h3 className="text-xl font-bold text-[#FF7F3F]">Mark B. Cervantes</h3>
              <p className="text-gray-600 mb-4">Chief Executive Officer</p>
              <p className="text-gray-700">
                Visionary leader with a passion for transforming vehicle rental experiences and driving innovation.
              </p>
            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.05 }}
              className="bg-gradient-to-br from-white to-orange-50 rounded-xl p-8 text-center shadow-lg"
            >
              <h3 className="text-xl font-bold text-[#FF7F3F]">Joanna Marie B. Cervantes</h3>
              <p className="text-gray-600 mb-4">Chief Operating Officer</p>
              <p className="text-gray-700">
                Operational expert ensuring seamless service delivery and exceptional customer satisfaction.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default AboutPage;