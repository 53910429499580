import React from 'react'
import CarList from '../../components/CustomerSide/CarList'
import Header from '../../components/CustomerSide/Header'
import Footer from '../../components/CustomerSide/Footer'

const Cars = () => {
  return (
    <div className="Cars">
      <Header />
        <CarList />
      <Footer />
    </div>
  )
}

export default Cars