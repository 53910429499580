import React, { useState, useEffect } from 'react';
import { 
  PlusIcon, 
  TrashIcon, 
  EditIcon, 
  SearchIcon,
  WrenchIcon,
  CurrencyIcon,
  ClipboardListIcon,
  BarChart2Icon,
  ClockIcon,
  EyeIcon
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  collection, 
  addDoc, 
  query, 
  getDocs, 
  deleteDoc, 
  doc,
  updateDoc
} from 'firebase/firestore';
import { db } from '../../Firebase/firebaseConfig';

const VehicleMaintenanceContent = () => {
  const [maintenanceRecords, setMaintenanceRecords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentStats, setCurrentStats] = useState([]);
  const [newRecord, setNewRecord] = useState({
    vehicleModel: '',
    vehicleYear: '',
    vehicleVIN: '',
    dateOfService: '',
    mileageAtService: '',
    servicePerformed: '',
    performedBy: '',
    cost: '',
    invoiceNumber: '',
    nextServiceDue: '',
    partsReplaced: '',
    warrantyInformation: '',
    notes: ''
  });

  // Micro Animation Variants
  const statsCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1, 
      y: 0,
      transition: {
        delay: index * 0.1,
        type: 'spring',
        stiffness: 100
      }
    })
  };

  const buttonHoverVariants = {
    rest: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { type: 'spring', stiffness: 300 }
    },
    tap: { scale: 0.95 }
  };

  const searchInputVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'spring', 
        stiffness: 120 
      }
    }
  };

  // Calculate Stats (previous implementation)
  const calculateStats = (records) => {
    const totalRecords = records.length;
    const totalCost = records.reduce((sum, record) => sum + parseFloat(record.cost || 0), 0);
    const averageCost = totalRecords > 0 ? (totalCost / totalRecords).toFixed(2) : 0;

    const statsData = [
      {
        title: 'Total Services',
        value: totalRecords,
        icon: ClipboardListIcon,
        bgColor: 'bg-blue-50',
        color: 'text-blue-500'
      },
      {
        title: 'Total Spend',
        value: `₱${totalCost.toFixed(2)}`,
        icon: CurrencyIcon,
        bgColor: 'bg-green-50',
        color: 'text-green-500'
      },
      {
        title: 'Avg. Service Cost',
        value: `₱${averageCost}`,
        icon: BarChart2Icon,
        bgColor: 'bg-yellow-50',
        color: 'text-yellow-500'
      },
      {
        title: 'Services This Month',
        value: records.filter(record => {
          const recordDate = new Date(record.dateOfService);
          const currentDate = new Date();
          return recordDate.getMonth() === currentDate.getMonth() &&
                 recordDate.getFullYear() === currentDate.getFullYear();
        }).length,
        icon: WrenchIcon,
        bgColor: 'bg-purple-50',
        color: 'text-purple-500'
      },
      {
        title: 'Overdue Services',
        value: records.filter(record => {
          const recordDate = new Date(record.dateOfService);
          const currentDate = new Date();
          const daysSinceService = (currentDate - recordDate) / (1000 * 60 * 60 * 24);
          return daysSinceService > 180; // More than 6 months
        }).length,
        icon: ClockIcon,
        bgColor: 'bg-red-50',
        color: 'text-red-500'
      }
    ];

    setCurrentStats(statsData);
  };

  useEffect(() => {
    const fetchMaintenanceRecords = async () => {
      try {
        const q = query(collection(db, 'Maintenance'));
        const querySnapshot = await getDocs(q);
        const records = querySnapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data() 
        }));
        setMaintenanceRecords(records);
        calculateStats(records);
      } catch (error) {
        console.error('Error fetching maintenance records:', error);
      }
    };
  
    fetchMaintenanceRecords();
  }, []);

  // Handle Input Change (unchanged)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Add Maintenance Record
  const handleAddMaintenanceRecord = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, 'Maintenance'), newRecord);
      
      const newFullRecord = { 
        id: docRef.id, 
        ...newRecord 
      };
      
      setMaintenanceRecords(prev => [...prev, newFullRecord]);
      calculateStats([...maintenanceRecords, newFullRecord]);
      
      // Reset form
      setNewRecord({
        vehicleModel: '',
        vehicleYear: '',
        vehicleVIN: '',
        dateOfService: '',
        mileageAtService: '',
        servicePerformed: '',
        performedBy: '',
        cost: '',
        invoiceNumber: '',
        nextServiceDue: '',
        partsReplaced: '',
        warrantyInformation: '',
        notes: ''
      });
      
      setIsModalOpen(false);
      setIsEditMode(false);
    } catch (error) {
      console.error('Error adding maintenance record:', error);
      alert('Failed to add maintenance record');
    }
  };

  // Update Maintenance Record
  const handleUpdateRecord = async (e) => {
    e.preventDefault();
    try {
      if (!selectedRecord) return;

      const recordRef = doc(db, 'Maintenance', selectedRecord.id);
      await updateDoc(recordRef, newRecord);

      // Update local state
      const updatedRecords = maintenanceRecords.map(record => 
        record.id === selectedRecord.id ? { ...record, ...newRecord } : record
      );

      setMaintenanceRecords(updatedRecords);
      calculateStats(updatedRecords);

      // Reset form and close modal
      setNewRecord({
        vehicleModel: '',
        vehicleYear: '',
        vehicleVIN: '',
        dateOfService: '',
        mileageAtService: '',
        servicePerformed: '',
        performedBy: '',
        cost: '',
        invoiceNumber: '',
        nextServiceDue: '',
        partsReplaced: '',
        warrantyInformation: '',
        notes: ''
      });
      
      setIsModalOpen(false);
      setIsEditMode(false);
      setSelectedRecord(null);
    } catch (error) {
      console.error('Error updating maintenance record:', error);
      alert('Failed to update maintenance record');
    }
  };

  // Prepare Edit
  const prepareEditRecord = (record) => {
    setSelectedRecord(record);
    setNewRecord({...record});
    setIsModalOpen(true);
    setIsEditMode(true);
  };

  // Delete Maintenance Record
  const handleDeleteRecord = async () => {
    if (!recordToDelete) return;
    
    try {
      await deleteDoc(doc(db, 'Maintenance', recordToDelete.id));
      const updatedRecords = maintenanceRecords.filter(record => record.id !== recordToDelete.id);
      setMaintenanceRecords(updatedRecords);
      calculateStats(updatedRecords);
      
      // Close delete confirmation
      setIsDeleteConfirmationOpen(false);
      setRecordToDelete(null);
    } catch (error) {
      console.error('Error deleting maintenance record:', error);
      alert('Failed to delete maintenance record');
    }
  };

  // Confirm Delete
  const confirmDelete = (record) => {
    setRecordToDelete(record);
    setIsDeleteConfirmationOpen(true);
  };

  // Open View Modal
  const handleViewRecord = (record) => {
    setSelectedRecord(record);
    setIsViewModalOpen(true);
  };

  // Filtered Records
  const filteredRecords = maintenanceRecords.filter(record => 
    (record.servicePerformed?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (record.performedBy?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
    (record.vehicleModel?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-6">
      {/* Stats Section with Animations */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
        {currentStats.map((stat, index) => (
          <motion.div 
            key={index} 
            variants={statsCardVariants}
            initial="hidden"
            animate="visible"
            custom={index}
            className={`p-4 rounded-lg flex items-center ${stat.bgColor}`}
          >
            <div className={`mr-4 ${stat.color}`}>
              <stat.icon size={24} />
            </div>
            <div>
              <p className="text-sm text-gray-500">{stat.title}</p>
              <p className="text-2xl font-bold">{stat.value}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Search and Add Maintenance Record Section with Animations */}
      <div className="flex flex-col md:flex-row gap-4 mb-6 justify-between items-center">
        <motion.div 
          variants={searchInputVariants}
          initial="initial"
          animate="animate"
          className="flex-grow relative w-full md:w-96"
        >
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search maintenance records..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white text-sm placeholder-gray-400 focus:outline-none transition-colors duration-200"
          />
        </motion.div>
        <motion.button 
          variants={buttonHoverVariants}
          initial="rest"
          whileHover="hover"
          whileTap="tap"
          onClick={() => setIsModalOpen(true)}
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          <PlusIcon className="mr-2 w-5 h-5" />
          Add Maintenance Record
        </motion.button>
      </div>

      {/* Maintenance Records Table */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vehicle</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mileage</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cost</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <AnimatePresence>
            <tbody>
              {filteredRecords.map((record) => (
                <motion.tr 
                  key={record.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="border-b hover:bg-gray-50"
                >
                  <td className="px-6 py-4">{record.vehicleModel} ({record.vehicleYear})</td>
                  <td className="px-6 py-4">{record.dateOfService}</td>
                  <td className="px-6 py-4">{record.mileageAtService} miles</td>
                  <td className="px-6 py-4">{record.servicePerformed}</td>
                  <td className="px-6 py-4">₱{record.cost}</td>
                  <td className="px-6 py-4 text-right">
                  <div className="flex justify-end space-x-2">
                <button 
                  className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                  onClick={() => handleViewRecord(record)}
                >
                  <EyeIcon className="w-5 h-5" />
                </button>
                <button 
                  className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                  onClick={() => prepareEditRecord(record)}
                >
                  <EditIcon className="w-5 h-5" />
                </button>
                <button 
                  className="text-red-500 hover:bg-red-100 p-2 rounded"
                  onClick={() => confirmDelete(record)}
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
              </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </AnimatePresence>
        </table>
      </div>
      
      {/* Delete Confirmation Modal */}
      {isDeleteConfirmationOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg p-6 w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-4 text-red-600">Confirm Deletion</h2>
            <p className="mb-4">
              Are you sure you want to delete the maintenance record for 
              {` ${recordToDelete?.vehicleModel} (${recordToDelete?.vehicleYear})`}?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsDeleteConfirmationOpen(false)}
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteRecord}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}

      {/* View Record Modal */}
      {isViewModalOpen && selectedRecord && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg p-6 w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-4">Maintenance Record Details</h2>
            <div className="space-y-2">
              <p><strong>Vehicle:</strong> {selectedRecord.vehicleModel} ({selectedRecord.vehicleYear})</p>
              <p><strong>VIN:</strong> {selectedRecord.vehicleVIN}</p>
              <p><strong>Date of Service:</strong> {selectedRecord.dateOfService}</p>
              <p><strong>Mileage:</strong> {selectedRecord.mileageAtService} miles</p>
              <p><strong>Service Performed:</strong> {selectedRecord.servicePerformed}</p>
              <p><strong>Performed By:</strong> {selectedRecord.performedBy}</p>
              <p><strong>Cost:</strong> ₱{selectedRecord.cost}</p>
              <p><strong>Invoice Number:</strong> {selectedRecord.invoiceNumber}</p>
              <p><strong>Notes:</strong> {selectedRecord.notes}</p>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsViewModalOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}

      {/* Add/Edit Maintenance Record Modal */}
      {isModalOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg p-6 w-full max-w-md"
          >
            <h2 className="text-2xl font-bold mb-4">
              {isEditMode ? 'Edit Maintenance Record' : 'Add Maintenance Record'}
            </h2>
            <form onSubmit={isEditMode ? handleUpdateRecord : handleAddMaintenanceRecord}>
              <div className="grid grid-cols-1 gap-4">
                <div className="grid grid-cols-2 gap-2">
                  <input
                    type="text"
                    name="vehicleModel"
                    placeholder="Vehicle Model"
                    value={newRecord.vehicleModel}
                    onChange={handleInputChange}
                    className="w-full border rounded-lg px-3 py-2"
                    required
                  />
                  <input
                    type="text"
                    name="vehicleYear"
                    placeholder="Year"
                    value={newRecord.vehicleYear}
                    onChange={handleInputChange}
                    className="w-full border rounded-lg px-3 py-2"
                    required
                  />
                </div>
                <input
                  type="text"
                  name="vehicleVIN"
                  placeholder="Vehicle VIN"
                  value={newRecord.vehicleVIN}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                />
                <input
                  type="date"
                  name="dateOfService"
                  value={newRecord.dateOfService}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                  required
                />
                <input
                  type="number"
                  name="mileageAtService"
                  placeholder="Mileage"
                  value={newRecord.mileageAtService}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                  required
                />
                <input
                  type="text"
                  name="servicePerformed"
                  placeholder="Service Performed"
                  value={newRecord.servicePerformed}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                  required
                />
                <input
                  type="text"
                  name="performedBy"
                  placeholder="Performed By"
                  value={newRecord.performedBy}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                  required
                />
                <input
                  type="number"
                  name="cost"
                  placeholder="Cost"
                  value={newRecord.cost}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                  required
                />
                <input
                  type="text"
                  name="invoiceNumber"
                  placeholder="Invoice Number"
                  value={newRecord.invoiceNumber}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                />
                <textarea
                  name="notes"
                  placeholder="Additional Notes"
                  value={newRecord.notes}
                  onChange={handleInputChange}
                  className="w-full border rounded-lg px-3 py-2"
                />
              </div>
              <div className="flex justify-end space-x-2 mt-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setIsEditMode(false);
                    setSelectedRecord(null);
                    // Reset form
                    setNewRecord({
                      vehicleModel: '',
                      vehicleYear: '',
                      vehicleVIN: '',
                      dateOfService: '',
                      mileageAtService: '',
                      servicePerformed: '',
                      performedBy: '',
                      cost: '',
                      invoiceNumber: '',
                      nextServiceDue: '',
                      partsReplaced: '',
                      warrantyInformation: '',
                      notes: ''
                    });
                  }}
                  className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  {isEditMode ? 'Update Record' : 'Save Record'}
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default VehicleMaintenanceContent;