import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import your pages
import Home from './pages/CustomerSide/Home';
import Cars from './pages/CustomerSide/Cars';
import Services from './pages/CustomerSide/Services';
import Contact from './pages/CustomerSide/Contact';
import About from './pages/CustomerSide/About';
import Profile from './pages/CustomerSide/Profile';
import SignIn from './pages/CustomerSide/SignIn';
import SignUp from './pages/CustomerSide/SignUp';
import ForgotPassword from './pages/CustomerSide/ForgotPassword';
import NotFound from './pages/NotFound';

// Import protection routes
import AdminProtectedRoute from './components/ManagementSide/AdminProtectedRoute';
import CustomerProtectedRoute from './components/CustomerSide/CustomerProtectedRoute';

// Import admin pages
import AdminDashboard from './pages/ManagementSide/AdminDashboard';

function App() {
  return (
    <Routes>
      {/* PUBLIC ROUTES - ONLY HOME, SIGN IN, SIGN UP, FORGOT PASSWORD */}
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Cars" element={<Cars />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/About" element={<About />} />
      <Route path="/SignIn" element={<SignIn />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />

      {/* CUSTOMER PROTECTED ROUTES */}
      <Route 
        path="/Profile" 
        element={
          <CustomerProtectedRoute>
            <Profile />
          </CustomerProtectedRoute>
        } 
      />

      {/* ADMIN PROTECTED ROUTES */}
      <Route 
        path="/AdminDashboard" 
        element={
          <AdminProtectedRoute>
            <AdminDashboard />
          </AdminProtectedRoute>
        } 
      />

      {/* 404 NOT FOUND ROUTE - MUST BE THE LAST ROUTE */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;