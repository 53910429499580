import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, User, LogOut, ChevronRight } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import 'tailwindcss/tailwind.css';

const Header = () => {
    const [scrolling, setScrolling] = useState(false);
    const [fullName, setFullName] = useState('');
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [showMobileLogoutConfirmation, setShowMobileLogoutConfirmation] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { user, signOut } = useAuth();
    const navigate = useNavigate();
    const db = getFirestore();

    const navigationItems = ['Home', 'Cars', 'Services', 'Contact', 'About'];

    useEffect(() => {
        const handleScroll = () => {
            setScrolling(window.scrollY > 20);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (user?.email) {
            const fetchCustomerByEmail = async () => {
                try {
                    const q = query(collection(db, 'Customers'), where('EmailAddress', '==', user.email));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const customerDoc = querySnapshot.docs[0];
                        const { FirstName, LastName } = customerDoc.data();
                        setFullName(`${FirstName} ${LastName}`);
                    }
                } catch (error) {
                    console.error("Error fetching customer data: ", error);
                }
            };

            fetchCustomerByEmail();
        }
    }, [user?.email, db]);

    const handleLogout = () => {
        signOut();
        setShowLogoutConfirmation(false);
        setShowMobileLogoutConfirmation(false);
    };

    const LogoutConfirmationModal = ({ onClose, onConfirm, isMobile = false }) => (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900/50 backdrop-blur-sm z-50">
            <div
                className="bg-white rounded-lg shadow-xl p-6 w-11/12 max-w-md mx-auto"
                id={isMobile ? "mobile-logout-confirmation-modal" : "logout-confirmation-modal"}
            >
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Confirm Logout</h3>
                <p className="text-gray-600 mb-4">Are you sure you want to log out?</p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );

    const NavLink = ({ to, children, onClick }) => (
      <Link
          to={to}
          onClick={onClick}
          className={`relative group flex items-center text-base font-medium transition-colors duration-200 ${
              scrolling ? 'text-white hover:text-orange-400' : 'text-gray-900 hover:text-orange-400'
          }`}
      >
          <span className="relative">
              {children}
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-orange-400 group-hover:w-full transition-all duration-300" />
          </span>
      </Link>
  );

    return (
      <header
      className={`py-4 md:py-6 fixed top-0 w-full z-50 transition-all duration-300 ${
          scrolling 
          ? 'bg-gray-900 shadow-lg' 
          : 'bg-white'
      }`}
  >
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
              <div className="flex-shrink-0">
                  <Link to="/" className="flex items-center space-x-2">
                      <h1 className={`text-3xl md:text-4xl font-extrabold ${
                          scrolling ? 'text-orange-400' : 'text-orange-400'
                      } hover:text-orange-500 transition-colors duration-300`}>
                          MKFLEET
                      </h1>
                  </Link>
              </div>

              <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className={`lg:hidden ${
                      scrolling ? 'text-white hover:text-gray-300' : 'text-gray-600 hover:text-gray-900'
                  } transition-colors duration-200`}
              >
                  {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>

              <nav className="hidden lg:flex lg:items-center lg:space-x-8">
                  {navigationItems.map((item) => (
                      <NavLink key={item} to={`/${item.toLowerCase()}`}>
                          {item}
                      </NavLink>
                  ))}
              </nav>

              <div className="hidden lg:flex lg:items-center lg:space-x-4">
                  {user ? (
                      <div className="flex items-center space-x-4">
                          <div
                              className={`flex items-center space-x-2 cursor-pointer ${
                                  scrolling ? 'text-white' : 'text-gray-900'
                              }`}
                              onClick={() => navigate('/Profile')}
                          >
                              <User className="text-orange-400" size={24} />
                              <span className="text-sm font-medium">{fullName || user.email}</span>
                          </div>

                          <button
                              onClick={() => setShowLogoutConfirmation(true)}
                              className="flex items-center space-x-2 px-4 py-2 bg-orange-400 text-white rounded-md hover:bg-orange-500 transition-colors duration-200"
                          >
                              <LogOut size={20} />
                              <span>Logout</span>
                          </button>
                      </div>
                  ) : (
                      <Link
                          to="/SignIn"
                          className="px-6 py-2 font-medium text-white bg-orange-400 rounded-md hover:bg-orange-500 transition-colors duration-200"
                      >
                          Sign In
                      </Link>
                  )}
              </div>
          </div>
      </div>

            {isMenuOpen && (
                <div className="fixed top-0 right-0 w-64 h-full bg-white shadow-xl z-50">
                    <div className="flex flex-col h-full p-6">
                        <div className="flex justify-end">
                            <button
                                onClick={() => setIsMenuOpen(false)}
                                className="text-gray-600 hover:text-gray-900"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <nav className="mt-8 space-y-4">
                            {navigationItems.map((item) => (
                                <div key={item} className="flex items-center space-x-2">
                                    <ChevronRight size={16} className="text-orange-400" />
                                    <NavLink to={`/${item}`} onClick={() => setIsMenuOpen(false)}>
                                        {item}
                                    </NavLink>
                                </div>
                            ))}
                        </nav>

                        <div className="mt-auto">
                            {user ? (
                                <>
                                    <div
                                        className="flex items-center space-x-2 mb-4 cursor-pointer"
                                        onClick={() => {
                                            navigate('/Profile');
                                            setIsMenuOpen(false);
                                        }}
                                    >
                                        <User className="text-orange-400" size={20} />
                                        <span className="text-sm font-medium">{fullName || user.email}</span>
                                    </div>

                                    <button
                                        onClick={() => setShowMobileLogoutConfirmation(true)}
                                        className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-orange-400 text-white rounded-md hover:bg-orange-500 transition-colors duration-200"
                                    >
                                        <LogOut size={20} />
                                        <span>Logout</span>
                                    </button>
                                </>
                            ) : (
                                <Link
                                    to="/SignIn"
                                    onClick={() => setIsMenuOpen(false)}
                                    className="w-full flex items-center justify-center px-6 py-2 font-medium text-white bg-orange-400 rounded-md hover:bg-orange-500 transition-colors duration-200"
                                >
                                    Sign In
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showLogoutConfirmation && (
                <LogoutConfirmationModal
                    onClose={() => setShowLogoutConfirmation(false)}
                    onConfirm={handleLogout}
                />
            )}

            {showMobileLogoutConfirmation && (
                <LogoutConfirmationModal
                    onClose={() => setShowMobileLogoutConfirmation(false)}
                    onConfirm={handleLogout}
                    isMobile
                />
            )}
        </header>
    );
};

export default Header;