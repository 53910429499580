import React from 'react';
import ForgotPasswordComponent from '../../components/CustomerSide/ForgotPasswordComponent';

const ForgotPassword = () => {
  return (
    <div className='SignUp'>
      <ForgotPasswordComponent />
    </div>
    
  )
}

export default ForgotPassword;