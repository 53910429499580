import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { signInWithEmailAndPassword, signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../Firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Check for authentication token and user email in cookies on page load
        const authToken = Cookies.get('authToken');
        const userEmail = Cookies.get('userEmail');
        
        if (authToken && userEmail) {
            setUser({ authToken, email: userEmail });
            console.log('User is logged in');
        } else {
            setUser(null);
            console.log('User is not logged in');
        }
        setLoading(false);
    }, []);

    // Sign-in function using Firebase Authentication
    const signIn = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const authToken = await user.getIdToken();

            // Store authToken and email in cookies (session-based)
            Cookies.set('authToken', authToken);
            Cookies.set('userEmail', email);

            setUser({ authToken, email });
            console.log('User successfully signed in');
            navigate('/Cars'); // Redirect to cars page after sign-in
        } catch (error) {
            console.error('Sign-in error:', error.message);
            throw error; // Let the caller handle the error
        }
    };

    // Sign-out function to clear cookies and Firebase sign-out
    const signOut = async () => {
        try {
            await firebaseSignOut(auth);
            Cookies.remove('authToken');
            Cookies.remove('userEmail');
            setUser(null);
            console.log('User successfully signed out');
            navigate('/SignIn'); // Redirect to sign-in page after sign-out
        } catch (error) {
            console.error('Sign-out error:', error.message);
        }
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }}>
            {!loading && children} {/* Render children once loading is complete */}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
