import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const CustomerProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const [isCustomer, setIsCustomer] = useState(false);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const checkCustomerRole = async () => {
      // If no user is logged in, immediately set loading to false
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        // Query Firestore to check user's role
        const q = query(
          collection(db, 'Customers'), 
          where('EmailAddress', '==', user.email)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          // Check if the user has 'Customer' role
          setIsCustomer(userData.Role === 'Customer');
        }
      } catch (error) {
        console.error('Error checking customer role:', error);
        setIsCustomer(false);
      } finally {
        setLoading(false);
      }
    };

    checkCustomerRole();
  }, [user, db]);

  // Show loading state while checking
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mb-4"></div>
          <p className="text-gray-700">Verifying access...</p>
        </div>
      </div>
    );
  }

  // If no user or not a customer, redirect to sign-in
  if (!user || !isCustomer) {
    return <Navigate to="/SignIn" replace />;
  }

  // If authenticated and customer, render children
  return children;
};

export default CustomerProtectedRoute;