import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaCarAlt, FaSearch, FaGasPump, FaCar, FaCalendarAlt } from 'react-icons/fa';
import { MdFilterList, MdAirlineSeatReclineNormal } from 'react-icons/md';
import { GiGearStickPattern } from 'react-icons/gi';
import BookingOverlay from './BookingOverlay';

const CarList = () => {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    make: '',
    year: '',
    transmission: '',
    fuelType: '',
  });
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const db = getFirestore();
        const vehiclesCollection = collection(db, 'Vehicles');
        const vehicleSnapshot = await getDocs(vehiclesCollection);

        if (vehicleSnapshot.empty) {
          console.warn("No vehicles found in Firestore.");
          return;
        }

        const storage = getStorage();
        const vehicleList = await Promise.all(
          vehicleSnapshot.docs.map(async (doc) => {
            const carData = doc.data();
            if (carData.status !== 'Available') {
              return null;
            }

            const relevantData = {
              id: doc.id,
              make: carData.make,
              model: carData.model,
              year: carData.year,
              color: carData.color,
              fuelType: carData.fuelType,
              transmissionType: carData.transmissionType,
              seatNumber: carData.seatNumber,
              status: carData.status,
            };

            let imageUrl = null;
            try {
              if (carData.carImage) {
                const imageRef = ref(storage, carData.carImage);
                imageUrl = await getDownloadURL(imageRef);
              }
            } catch (error) {
              console.warn("Error fetching image:", error);
            }
            
            return { ...relevantData, imageUrl };
          })
        );

        const filteredVehicleList = vehicleList.filter(vehicle => vehicle !== null);
        setCars(filteredVehicleList);
        setFilteredCars(filteredVehicleList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let result = [...cars];

      // Apply search query
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        result = result.filter(car => 
          car.make.toLowerCase().includes(query) ||
          car.model.toLowerCase().includes(query) ||
          car.color.toLowerCase().includes(query) ||
          car.year.toString().includes(query)
        );
      }

      // Apply additional filters
      if (filters.make) {
        result = result.filter(car => 
          car.make.toLowerCase() === filters.make.toLowerCase()
        );
      }
      if (filters.year) {
        result = result.filter(car => 
          car.year.toString() === filters.year
        );
      }
      if (filters.transmission) {
        result = result.filter(car => 
          car.transmissionType.toLowerCase() === filters.transmission.toLowerCase()
        );
      }
      if (filters.fuelType) {
        result = result.filter(car => 
          car.fuelType.toLowerCase() === filters.fuelType.toLowerCase()
        );
      }

      setFilteredCars(result);
    };

    applyFilters();
  }, [searchQuery, filters, cars]);

  const handleBookNowClick = (car) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setSelectedCar(car);
        setShowOverlay(true);
      } else {
        navigate('/SignIn');
      }
    });
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedCar(null);
  };

  // Get unique values for filters
  const uniqueMakes = [...new Set(cars.map(car => car.make))].sort();
  const uniqueYears = [...new Set(cars.map(car => car.year))].sort((a, b) => b - a);
  const uniqueTransmissions = [...new Set(cars.map(car => car.transmissionType))];
  const uniqueFuelTypes = [...new Set(cars.map(car => car.fuelType))];

  const sanitizeSearchQuery = (input) => {
    // Allow only alphanumeric characters, spaces, and some basic punctuation
    return input.replace(/[^a-zA-Z0-9\s]/g, '');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="flex flex-col items-center text-white space-y-4">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-orange-500"></div>
          <span className="text-2xl">Loading available vehicles...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto pt-32 sm:pt-12 md:pt-16 lg:pt-32 bg-gray-900 p-4">
      <div className="max-w-7xl mx-auto mb-8">
        {/* Search and Filter Section */}
        <div className="bg-white rounded-lg p-4 mb-6 shadow-md">
          <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search by make, model, color, or year..."
              value={searchQuery}
              onChange={(e) => {
                const sanitizedValue = sanitizeSearchQuery(e.target.value);
                setSearchQuery(sanitizedValue);
              }}
              maxLength={50}
              className="w-full pl-10 pr-20 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-400"
            />
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <span className="absolute right-3 top-3 text-gray-400 text-sm">
              {searchQuery.length}/50
            </span>
          </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 px-4 py-2 bg-orange-400 text-white rounded-lg hover:bg-orange-500 transition duration-300"
            >
              <MdFilterList />
              Filters
            </button>
          </div>

          {/* Filter Options */}
          {showFilters && (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <select
                value={filters.make}
                onChange={(e) => setFilters({...filters, make: e.target.value})}
                className="p-2 border rounded-lg focus:ring-2 focus:ring-orange-400"
              >
                <option value="">All Makes</option>
                {uniqueMakes.map(make => (
                  <option key={make} value={make}>{make}</option>
                ))}
              </select>

              <select
                value={filters.year}
                onChange={(e) => setFilters({...filters, year: e.target.value})}
                className="p-2 border rounded-lg focus:ring-2 focus:ring-orange-400"
              >
                <option value="">All Years</option>
                {uniqueYears.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>

              <select
                value={filters.transmission}
                onChange={(e) => setFilters({...filters, transmission: e.target.value})}
                className="p-2 border rounded-lg focus:ring-2 focus:ring-orange-400"
              >
                <option value="">All Transmissions</option>
                {uniqueTransmissions.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>

              <select
                value={filters.fuelType}
                onChange={(e) => setFilters({...filters, fuelType: e.target.value})}
                className="p-2 border rounded-lg focus:ring-2 focus:ring-orange-400"
              >
                <option value="">All Fuel Types</option>
                {uniqueFuelTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Results Count */}
        <div className="text-white mb-4">
          {filteredCars.length} {filteredCars.length === 1 ? 'vehicle' : 'vehicles'} Available
        </div>

        {/* Cars Grid */}
        {filteredCars.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredCars.map(car => (
              <div 
                key={car.id} 
                className="w-full max-w-[500px] mx-auto overflow-hidden bg-white rounded-lg shadow-md transform transition duration-300 hover:scale-105"
              >
                <div className="relative w-full h-[250px]">
                  <img
                    className="object-cover w-full h-full"
                    src={car.imageUrl || "https://via.placeholder.com/400x250?text=No+Image"}
                    alt={`${car.make} ${car.model}`}
                  />
                  <div className="absolute top-2 right-2 bg-green-500 text-white px-3 py-1 rounded-full text-sm font-semibold">
                    Available
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h2 className="text-2xl font-bold text-gray-900">{car.make} {car.model}</h2>
                      <div className="flex items-center mt-1 text-gray-600">
                        <FaCalendarAlt className="mr-2" />
                        <span>{car.year}</span>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-3 mb-4">
                    <div className="flex items-center text-gray-600">
                      <MdAirlineSeatReclineNormal className="mr-2" />
                      <span>{car.seatNumber} Seats</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <FaGasPump className="mr-2" />
                      <span>{car.fuelType}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <GiGearStickPattern className="mr-2" />
                      <span>{car.transmissionType}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <FaCar className="mr-2" />
                      <span>{car.color}</span>
                    </div>
                  </div>

                  <button
                    onClick={() => handleBookNowClick(car)}
                    className="mt-4 w-full px-4 py-3 text-base font-bold text-white bg-orange-400 rounded-lg hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-300"
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[400px] text-white text-3xl space-y-4">
            <FaCarAlt size={80} />
            <span>No vehicles found matching your criteria</span>
            <button
              onClick={() => {
                setSearchQuery('');
                setFilters({
                  make: '',
                  year: '',
                  transmission: '',
                  fuelType: '',
                });
              }}
              className="text-base px-6 py-2 bg-orange-400 rounded-lg hover:bg-orange-500 transition duration-300"
            >
              Reset Filters
            </button>
          </div>
        )}
      </div>
      
      {showOverlay && selectedCar && (
        <BookingOverlay car={selectedCar} onClose={handleCloseOverlay} />
      )}
    </div>
  );
};

export default CarList;