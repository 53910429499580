import React from 'react';
import { FaFacebook } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-900 py-12 text-white">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-8 lg:space-y-0">
  
          {/* Logo Section - Centered on Mobile */}
          <div className="text-center lg:text-left w-full lg:w-auto">
            <h1 className="text-4xl font-bold text-orange-400">MKFLEET</h1>
          </div>

          {/* Navigation Links - Stacked on Mobile, Spread on Desktop */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full lg:w-auto text-center lg:text-left">
            <div>
              <h2 className="text-lg font-bold mb-4">Learn More</h2>
              <ul className="space-y-2">
                <li>
                  <a href="/about" className="hover:underline">About MKFLEET</a>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-4">Bookings</h2>
              <ul className="space-y-2">
                <li>
                  <a href="/Cars" className="hover:underline">Available Vehicles</a>
                </li>
              </ul>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-4">Contact Us</h2>
              <ul className="space-y-2">
                <li>Email: <a href="mailto:maguklowitransposervices@gmail.com" className="hover:underline ml-1">maguklowitransposervices@gmail.com</a></li>
                <li>Mobile: <a href="tel:+639123456789" className="hover:underline ml-1">+63-96-942-73025</a></li>
              </ul>
            </div>
          </div>

          {/* Social Media Links - Centered on Mobile */}
          <div className="flex justify-center lg:justify-end w-full lg:w-auto space-x-4">
            <a 
              href="https://www.facebook.com/maguklowiservices?mibextid=ZbWKwL" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:text-opacity-80"
            >
              <FaFacebook size={24} />
            </a>
          </div>
        </div>

        {/* Footer Bottom - Centered */}
        <div className="mt-8 border-t border-gray-700 pt-4 text-center">
          <p>© 2024 MKFLEET | All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;