import React from 'react';
import { motion } from 'framer-motion';
import { 
  MapPin, 
  Phone, 
  Mail, 
  Clock, 
  Facebook, 
  CheckCircle
} from 'lucide-react';

const Contact = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen pt-6 md:pt-12">
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="container mx-auto px-4 py-8 md:py-16"
      >
        {/* Engaging Welcome Section */}
        <motion.section 
          variants={itemVariants}
          className="bg-gradient-to-br from-white to-orange-50 rounded-lg shadow-md p-6 md:p-10 mb-8 md:mb-12 text-center"
        >
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Your Trusted Transportation Partner
          </h1>
          <div className="max-w-4xl mx-auto">
            <p className="text-lg md:text-xl text-gray-700 mb-6">
              At Magu Klowi Transpo Services, we're more than just a transportation company – we're your dedicated solution for seamless, reliable, and efficient mobility.
            </p>
            <div className="flex justify-center space-x-4 md:space-x-6">
              <div className="flex items-center space-x-2">
                <CheckCircle className="text-orange-500 w-6 h-6 md:w-8 md:h-8" />
                <span className="text-gray-800 font-medium">24/7 Support</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircle className="text-orange-500 w-6 h-6 md:w-8 md:h-8" />
                <span className="text-gray-800 font-medium">Flexible Solutions</span>
              </div>
              <div className="flex items-center space-x-2">
                <CheckCircle className="text-orange-500 w-6 h-6 md:w-8 md:h-8" />
                <span className="text-gray-800 font-medium">Customer-First Approach</span>
              </div>
            </div>
            <p className="mt-6 text-base md:text-lg text-gray-600 italic">
              "Ready to transform your transportation experience? We're here to make it happen. Reliable Rides, Anytime, Anywhere."
            </p>
          </div>
        </motion.section>

        {/* Rest of the existing contact component remains the same */}
        {/* Contact Information Section */}
        <motion.section 
          variants={itemVariants}
          className="bg-white rounded-lg shadow-md p-4 md:p-8 mb-8 md:mb-12"
        >
          <h2 className="text-2xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6 text-center">Contact Magu Klowi</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
            <motion.div 
              variants={itemVariants}
              className="flex items-center space-x-3 md:space-x-4 text-sm md:text-base"
            >
              <MapPin className="text-orange-500 w-8 h-8 md:w-12 md:h-12" />
              <div>
                <h3 className="font-semibold text-gray-800">Address</h3>
                <p className="text-gray-600">16-10 Independence, Valenzuela, Metro Manila</p>
              </div>
            </motion.div>

            <motion.div 
              variants={itemVariants}
              className="flex items-center space-x-3 md:space-x-4 text-sm md:text-base"
            >
              <Phone className="text-orange-500 w-8 h-8 md:w-12 md:h-12" />
              <div>
                <h3 className="font-semibold text-gray-800">Phone</h3>
                <a 
                  href="tel:+639123456789" 
                  className="text-gray-600 hover:text-orange-600 transition text-sm md:text-base"
                >
                  +63-96-942-73025
                </a>
              </div>
            </motion.div>

            <motion.div 
              variants={itemVariants}
              className="flex items-center space-x-3 md:space-x-4 text-sm md:text-base"
            >
              <Mail className="text-orange-500 w-8 h-8 md:w-12 md:h-12" />
              <div>
                <h3 className="font-semibold text-gray-800">Email</h3>
                <a 
                  href="mailto:maguklowitransposervices@gmail.com" 
                  className="text-gray-600 hover:text-orange-600 transition text-sm md:text-base break-words"
                >
                  maguklowitransposervices@gmail.com
                </a>
              </div>
            </motion.div>
          </div>
        </motion.section>

        {/* Existing Business Hours and Social Media sections remain unchanged */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {/* Business Hours Section */}
          <motion.section 
            variants={itemVariants}
            className="bg-[#F5FCFC] rounded-lg shadow-md p-4 md:p-8"
          >
            <div className="flex items-center mb-4 md:mb-6">
              <Clock className="text-orange-500 w-8 h-8 md:w-10 md:h-10 mr-2 md:mr-4" />
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900">Business Hours</h2>
            </div>
            <div className="space-y-2 md:space-y-4 text-sm md:text-base">
              <div className="flex justify-between">
                <span>Monday - Friday</span>
                <span>9:00 AM - 6:00 PM</span>
              </div>
              <div className="flex justify-between">
                <span>Saturday</span>
                <span>10:00 AM - 4:00 PM</span>
              </div>
              <div className="flex justify-between">
                <span>Sunday</span>
                <span>Closed</span>
              </div>
            </div>
          </motion.section>

          {/* Social Media Section */}
          <motion.section 
            variants={itemVariants}
            className="mt-8 md:mt-12 text-center"
          >
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 md:mb-6">Follow Us</h2>
            <div className="flex justify-center space-x-4 md:space-x-6">
              <motion.a 
                href="https://www.facebook.com/maguklowiservices?mibextid=ZbWKwL"
                target="_blank" 
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="text-gray-600 hover:text-blue-600"
              >
                <Facebook className="w-8 h-8 md:w-10 md:h-10" />
              </motion.a>
            </div>
          </motion.section>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;